import React, {ImgHTMLAttributes} from 'react';
import {Button, ButtonProps, Icon, Popup, PopupContentProps, SemanticShorthandItem} from 'semantic-ui-react';

const ButtonWithImage = (props: { content: string, imageAttributes: ImgHTMLAttributes<any>, labelPosition: ButtonProps['labelPosition'], disabledButtonPopupContent?: SemanticShorthandItem<PopupContentProps> } & Omit<ButtonProps, 'labelPosition' | 'label' | 'icon'>) => {
    const {content, imageAttributes, disabledButtonPopupContent, ...btnProps} = props;
    const buttonIsDisabled = props?.disabled ?? false;
    return <Popup
        content={disabledButtonPopupContent}
        disabled={!buttonIsDisabled}
        trigger={<div style={{ display: 'inline-block', ...(buttonIsDisabled ? {cursor: 'not-allowed'}:{}) }}>
                <Button icon {...btnProps}>
                    <Icon style={{padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img
                            style={{height: '25px', width: '25px'}}
                            {...imageAttributes}
                        />
                    </Icon>
                    {content}
                </Button>
            </div>}
    />
}
export default ButtonWithImage;