import { useDashboardService } from "../../platform/assets-management/builder/DashboardConfigService";
import { useUpdateOnGlobalContextChange } from "../../platform/assets-management/builder/widgets/commons";
import {
  FilterProps,
  useLineItemFilter,
} from "../../platform/lineitems-store/MetadataFilters";
import { StoreQuery } from "../../platform/ps-models/lineitems-store";
import { buildQueryFromFilters } from "../../platform/assets-management/scenarios/ScenarioFilter.component";

export function ScenarioFilters() {
  let { getService } = useDashboardService();
  const dService = getService();
  const store = dService.getStore();

  useUpdateOnGlobalContextChange({
    appContext: dService,
    id: "ctxLoader.ScenarioFilters",
  });

  let filterConfig: Record<string, Partial<FilterProps> | string> = {};

  dService
    .getConfig()
    .getFilters()
    .forEach((filter) => {
      const filterFieldName = filter.getFromSourceStore ? `source_${filter.fieldName}` : filter.fieldName;
      filterConfig[filterFieldName] = {
        label: filter.fiterLabel,
        multiSelect: true,
        formattedOptions: filter?.formattedOptions
      };
    });

  const onFilterChange = (
    query: StoreQuery,
    selectedFilters: Record<string, string[]>
  ) => {
    console.info("On Filter Change", selectedFilters);
    // Global Context holds everything that we want to persist for runtime.
    dService.updateGlobalContext({
      filterDetails: {
        filterQuery: buildQueryFromFilters(selectedFilters),
        filtersMap: selectedFilters,
        initialFiltersMap: dService.getGlobalContext()?.filterDetails?.initialFiltersMap,
      },
    });
  };

  let [, filterComponent] = useLineItemFilter(filterConfig, store, {
    onFilterChange,
    initialSelection: dService.getGlobalContext()?.filterDetails?.filtersMap,
  });

  return <div>{filterComponent}</div>;
}
