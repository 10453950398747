import {LineItemsStore} from "../ps-models/lineitems-store";
import {Select} from "semantic-ui-react";
import {MultiSelect} from "../ui/MultiSelect";
import React, {useEffect, useState} from "react";
import {ValueType} from "../ps-models/line-items";
import {
    convertToNumberStringOrBoolean,
    FinancialValueType,
    formatValueWithValueType,
    VALUE_TYPE_KEY,
} from "../ps-models";

export function MetadataFieldFilter({
                                      placeholder,
                                      fieldName,
                                      store,
                                      onChange, multiple,
                                      initialSelections,
                                      direction, formattedOptions
                                    }: { placeholder: string,
  multiple?: boolean,
  direction?: "left" | "right",
  fieldName: string, store: LineItemsStore, onChange: (value: string[]) => void, initialSelections?: string[], formattedOptions?:boolean}) {
  const [selections, setSelections] = useState<string[]>([]);

  let options = store.getExecution()
    .getFieldValues(fieldName).sort()

  const valueTypeForField = !formattedOptions ? undefined : store.getDataSet().getLineItemInContext(fieldName.replace("source_", ""), fieldName, (options?.[0] ?? "").toString())?.fields?.getField(VALUE_TYPE_KEY)?.value as FinancialValueType | undefined;
    const handleSelectionChange = (selections: string[])=>{
        onChange(selections)
        setSelections(selections)
    }

    useEffect(()=>{
        if(initialSelections !==undefined){
            const applicableOptions = options.filter(o => initialSelections?.includes(o.toString()))
                .map(it => it.toString());
          setSelections(applicableOptions)
        } else if(selections.length !==0){ // To clear past selections, when switching amongst scenarios
          setSelections([])
        }
    }, [initialSelections])
    const buildOptionFromFieldValue = (fieldValue: ValueType)=>{
        return buildOptionForField(fieldValue, valueTypeForField);
    }

     if(multiple){
         return <MultiSelect
             placeholderText={selections.length === 0 ? placeholder: `${placeholder} (*)`}
             hideSelectAll
             showSelectAllBtn
             showUnSelectAllBtn
             hideSelectByGroup
             searchable
             direction={direction}
             options={options.map(buildOptionFromFieldValue)}
             initialSelection={options.filter(o => selections?.includes(o.toString())).map(buildOptionFromFieldValue)}
             onApply={(selectedOptions) =>
                 handleSelectionChange(selectedOptions.map(o=>o.value.toString()) as string[])
                 }
         />
     }
    // @TODO: This misses support for initialSelections
  return <span  className={"metadata-field-filter"}><Select

    placeholder={placeholder}
    onChange={(e, {value}) => onChange(
      multiple ? value as string[] : [value as string]
    )}
    clearable={true}
    multiple={multiple}
    search
    options={options.map(it => ({value: it, text: it, key: it}))}/></span>
}

const buildOptionForField = (value: ValueType, valueTypeForField: FinancialValueType | undefined)=>{
    let valueTextRepresentation = value.toString();
    if(valueTypeForField){
        valueTextRepresentation = formatValueWithValueType(convertToNumberStringOrBoolean(value), valueTypeForField);
    }
    return {value: value.toString(), text: valueTextRepresentation, key: value.toString()}
};