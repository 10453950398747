import {getAmDashboards} from "./Dashboard.client";
import {useCompany, useCompanyId} from "../../core";
import React, {useEffect, useState} from "react";
import {AmDashboard} from "../../ps-types";
import {SectionsContent, SectionService, SectionsMenu} from "../Section";
import {DashboardBoardView} from "./DashboardView";
import {Container, Grid, Header, Image} from "semantic-ui-react";
import {RefreshBtn} from "../index";
import {getAmProjectConfig} from "../../ps-models";
import {authStorage} from "../../auth";

export function DashboardMainPage() {

  let companyId =  useCompanyId();

  let [dashboards, setDashboards] = useState<AmDashboard[]>();
  const company = authStorage.getCompany();
  const { namespace } = getAmProjectConfig(company);
  let companyLogoPath = "/am-logos/FourthPartnerLogo.png";
  if(namespace === "PUBLICDEMO"){
    companyLogoPath = "/logo512.png";
  } else if(namespace === "Ampyr"){
    companyLogoPath = "/am-logos/Ampyr.png";
  } else if(namespace === "KingEnergy"){
    companyLogoPath = "/am-logos/king-energy-logo.png";
  }


  useEffect(() => {
    getAmDashboards(companyId)
      .then((res) => {
        setDashboards(res);
      });
  }, [companyId]);

  const BASE_PATH = `/ps/:companyId/am/dashboard`;

  const builder = new SectionService(BASE_PATH, companyId);

  //Sort by navigationConfig.order
  dashboards?.sort((a, b) => {
    return (a.navigationConfig?.order || 0) - (b.navigationConfig?.order || 0);
  });

  dashboards?.filter(d => d.navigationConfig)
    .forEach((d, index) => {
      builder.addSection({
        title: d.navigationConfig!.menuName || d.name,
        key: d.navigationConfig!.key,
        parent: d.navigationConfig!.parentId,
        isHome: index === 0,
        render: () => {
          return <div>
                    <DashboardBoardView
                        key={d.id}
                        dashboardId={d.id} dashboardData={d} />
                  </div>
        }
      });
  });


  return <>
        <Header>
          <Container>
            <Grid  verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column width={2}>
                  <div style={{paddingLeft: "20px"}}>
                  <Image width={170} src={companyLogoPath}/>
                </div>
                </Grid.Column>
                <Grid.Column width={11} textAlign="center" >
                  <SectionsMenu sectionService={builder} />
                </Grid.Column>
                <Grid.Column  width={3}>
                  <RefreshBtn  />
                </Grid.Column>

              </Grid.Row>
            </Grid>
          </Container>
        </Header>
        <SectionsContent sectionService={builder}/>
  </>
}