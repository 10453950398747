import {useHistory, useLocation} from "react-router-dom";
import {last} from "ramda";
import {useCompanyId, useCurrentUser} from "../core";
import {Menu} from "semantic-ui-react";
import React from "react";
import {useAMCompanyHome} from "./config";

export function AssetsManagementMenu() {
    const history = useHistory();

    let currentUser = useCurrentUser();

    let path = last(useLocation().pathname.split("/"));

    const companyId = useCompanyId();

    const home = useAMCompanyHome()

    return (
        <Menu>
            <Menu.Item
                active={path === 'dashboard'}
                onClick={() => history.push(home)}
            >
              Dashboard
            </Menu.Item>

          {currentUser?.isAdmin() && <Menu.Item
            active={path === 'Board Builder'}
            onClick={() => history.push(`/ps/${companyId}/am/board-builder`)}
          >
            Board Builder
          </Menu.Item>}

          <Menu.Item
            active={path === 'projects'}
            onClick={() => history.push(`/ps/${companyId}/am/projects`)}
          >
            Projects
          </Menu.Item>

          <Menu.Item
            active={path === 'reports'}
            onClick={() => history.push(`/ps/${companyId}/am/reports`)}
          >
            Reports
          </Menu.Item>
          {currentUser?.isAdmin() && <>
            <Menu.Item
                active={path === 'key-management'}
                onClick={() => history.push(`/ps/${companyId}/key-management`)}
            >
                IOT API Setup
            </Menu.Item>

              <Menu.Item
                  active={path === 'integrations'}
                  onClick={() => history.push(`/ps/${companyId}/assets-management/integrations`)}
              >
                  Integrations
              </Menu.Item>
              <Menu.Item
                  active={path === 'line-items-editor'}
                  onClick={() => history.push(`/ps/${companyId}/assets-management/line-items-editor`)}
              >
                  Line Items Editor
              </Menu.Item>
              <Menu.Item
                  active={path === 'line-items-batch-updater'}
                  onClick={() => history.push(`/ps/${companyId}/assets-management/line-items-batch-updater`)}
              >
                  Line Items Batch Updater
              </Menu.Item>
              {/* @TODO: Restore this to get the report-builder to work */}
              {/*<Menu.Item*/}
              {/*    active={path === 'report-builder'}*/}
              {/*    onClick={() => history.push(`/ps/${companyId}/assets-management/report-builder`)}*/}
              {/*>*/}
              {/*    Report Builder*/}
              {/*</Menu.Item>*/}
              <Menu.Item
                  active={path === 'list-of-sites'}
                  onClick={() => history.push(`/ps/${companyId}/assets-management/list-of-sites`)}
              >
                  Site Manager
              </Menu.Item>

            </>}

        </Menu>
    )
}