import {DashboardBoardView} from "../builder/DashboardView";
import React, {useEffect, useState} from "react";
import {useCompany, useCompanyId} from "../../core";
import {getAmDashboard} from "../builder/Dashboard.client";
import {DashboardConfigService, getSortIndex} from "../builder/DashboardConfigService";
import {Button, ButtonGroup, ButtonOr, Container, Grid, Header, Icon, Input, Message, Segment, Table} from "semantic-ui-react";
import {generateAmReport, getAmReport, getAmReportDownloadUrl, getAmReportVersion, uploadAmReport} from "./amReports.client";
import {
    AmDashboard,
    AMModuleNames,
    AmReport,
    AmReportAndVersionsView,
    AmReportVersion,
    AmReportVersionView
} from "../../ps-types";
import {QueryResultData} from "../../ps-models/lineitems-store";
import { useMessages } from "../../ui/MessagesProvider";
import { Loading, LoadingBlock, SmallLoading } from "../../ui/Loading";
import {
    getAmProjectConfig,
    OutputMapping,
    ExcelReportInjectionConfig,
    utcDate,
    formatDateByTimeUnit
} from "../../ps-models";
import { FileUploader } from "react-drag-drop-files";

function formatDate(time: number) {
    let date = new Date(time);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function ReportDetailPage({reportId}: {reportId: string}) {
    const companyId = useCompanyId();
    const company = useCompany();
    const { namespace } = getAmProjectConfig(company);
    const amProjectId = namespace;
    let {clear, error} = useMessages();
    const [report, setReport] = useState<AmReportAndVersionsView>();
    const [selectedVersion, setSelectedVersion] = useState<AmReportVersionView>();
    const [versionData, setVersionData] = useState<AmReportVersion>();
    const [loadingVersion, setLoadingVersion] = useState<boolean>(false);
    const [dService, setDService] = useState<DashboardConfigService>();
    const [dashboard, setDashboard] = useState<AmDashboard>();
    const [generatingReport, setGeneratingReport] = useState<boolean>(false);

    useEffect(() => {
        (async function loadReport() {
            let report = await getAmReport(companyId, reportId);
            setReport(report);

            if(report.versions.length > 0) {
                setSelectedVersion(report.versions[0]);
            }
            let dashboard = await getAmDashboard(companyId, report.dashboardId);
            setDashboard(dashboard);
        })();
    }, []);

    useEffect(()=> {
        (async function loadVersion() {
            if(report && selectedVersion) {
                setLoadingVersion(true);
                versionData && setVersionData(undefined);
                let updatedVersionData = await getAmReportVersion(companyId, reportId, selectedVersion.versionId);
                setVersionData(updatedVersionData);
                setLoadingVersion(false);
            }
        })();
    }, [selectedVersion])

    const handleDownloadReport = async (versionId: string) => {
        if(!report) {
            console.error("Report not loaded");
            return;
        }
       
        let res = await getAmReportDownloadUrl(companyId, reportId, versionId);
        window.open(res.url, "_blank")
    }

    const handleGenerateReport = async () => {
        clear();
        setGeneratingReport(true);
        if(!dService) {
            console.error("Dashboard service not initialized");
            setGeneratingReport(false);
            return;
        }
        if(!report) {
            console.error("Report not loaded");
            setGeneratingReport(false);
            return;
        }
        if(!dashboard) {
            console.error("Template Dashboard not loaded");
            setGeneratingReport(false);
            return;
        }
        const outputMapping: OutputMapping = {};

        const widgets = dService.getAllWidgets()
            .sort((a, b) =>
                getSortIndex(a.config) - getSortIndex(b.config));

        let errorList: string[] = [];
        for(let widget of widgets){
            let widgetOutput = widget.getOutputs();
            if('queryResultData' in widgetOutput){
                outputMapping[widget.machineName] = {queryResultData:  widgetOutput['queryResultData'] as QueryResultData};
            } else if('value' in widgetOutput){
                let value = widgetOutput['value'];
                if(widget.config.type === 'string'){
                    console.info("should be here", value)
                    if(!value){
                        errorList.push(`${widget.machineName}`);
                    } else {
                        outputMapping[widget.machineName] = {value};
                    }
                } else {
                    value = parseInt(value);
                    if(isNaN(value)) {
                        errorList.push(`${widget.machineName}`);
                    } else {
                        outputMapping[widget.machineName] = {value};
                    }
                }
            }

            if(!widgetOutput) {
                console.warn(`No data found for widget ${widget.machineName}`);
            }
        }

        if(errorList.length > 0) {
            error(`Missing Values: ${errorList.join(", ")}`);
            setGeneratingReport(false);
            return;
        }
        const injectionConfig: ExcelReportInjectionConfig = dashboard?.reportTemplateConfig?.injectionConfig ?? buildDefaultInjectionConfig(amProjectId, outputMapping);

        for(let injection of injectionConfig){
            if(!outputMapping[injection.outputOf]){
                console.error(`No data found for ${injection.outputOf}`);
                error(`No data found ${injection.outputOf}`);
                setGeneratingReport(false);
                return;
            }
        }

        try {
            let updatedReport = await generateAmReport(companyId, reportId, outputMapping,{
                ...(dashboard?.reportTemplateConfig ?? {}),
                injectionConfig
            });
            setGeneratingReport(false);
            setReport(updatedReport);
        } catch (e) {
            console.error("Error generating report", e);
            error("Error generating report");
        }

        setGeneratingReport(false);
    }

    const handleSelectVersion = async (versionId: string) => {
        if(!report) {
            console.error("Report not loaded");
            return;
        }
        let selectedVersion = report.versions.find(v => v.versionId === versionId);
        if(!selectedVersion) {
            console.error("Version not found");
            return;
        }
        console.info("Selecting", selectedVersion);
        setSelectedVersion(selectedVersion);
    }

    const handleFileUpload = async (file: File) => {
        if(!report) {
            console.error("Report not loaded");
            return;
        }
        let updatedReport = await uploadAmReport(companyId, reportId, file);
        setReport(updatedReport);
    }


    return (
        <Container>
            <Segment style={{position:'relative'}}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}><div >
                                    <h2>{report?.name}</h2>
                            {report?.config && <div>
                                <><strong>For</strong> {formatDateByTimeUnit(report.config.dateRange.from, 'months')} <br/></>
                            </div>}

                            {report?.createdAt && <div>
                                <strong>Created At</strong> {formatDate(report.createdAt)} <br/>
                            </div>}

                            <p>&nbsp;</p>
                            <div   style={{position: 'absolute', bottom: "10px", left: "10px", fontSize: "12px"}}>

                            <ButtonGroup>

                            <Button 
                            primary
                            disabled={generatingReport}
                            loading={generatingReport}
                            onClick={handleGenerateReport}>Create New Version</Button>

                            <ButtonOr />

                            <FileUploader 
                            handleChange={handleFileUpload}
                            name="file" types={["csv", "xls", "xlsx", "xlsm"]}>
                                <Button Secondary>Upload Report</Button>
                            </FileUploader>
                            </ButtonGroup>
                            </div>

                            
                            <br/>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <strong>Versions</strong>
                            <Table celled
                                      selectable
                                      compact
                                      size='small'
                                      style={{cursor: 'pointer'}}    
                            >
                                <Table.Header>
                                    <Table.Row>
                                    <Table.HeaderCell>Final</Table.HeaderCell>
                                        <Table.HeaderCell>Version</Table.HeaderCell>
                                        <Table.HeaderCell>Created At</Table.HeaderCell>
                                        <Table.HeaderCell>Download</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {report?.versions.map(v => (
                                        <Table.Row key={v.versionId} 
                                        onClick={() => handleSelectVersion(v.versionId)}
                                        active={v.versionId === selectedVersion?.versionId} 
                                        data-version-id={v.versionId}>
                                            <Table.Cell>
                                                <Input type='radio' checked={v.versionId === report.defaultVersion} readOnly/>
                                            </Table.Cell>
                                            <Table.Cell>{v.versionId}</Table.Cell>
                                            <Table.Cell>{formatDate(v.createdAt)}</Table.Cell>
                                            <Table.Cell>
                                                <Button 
                                                icon="download"
                                                size="mini"
                                                onClick={() => handleDownloadReport(v.versionId)} />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
               </Grid>
                    
            </Segment>
            {loadingVersion && <SmallLoading message="Loading Version" />}
            {report && selectedVersion && selectedVersion.manualUpload &&  <Message>This version was uploaded manually</Message>}
            {report && dashboard && <DashboardBoardView
              dashboardId={dashboard.id}
              dashboardData={dashboard}
              widgetParams={(versionData?.data?.outputMapping ? Object.keys(versionData?.data?.outputMapping as OutputMapping) :  (new DashboardConfigService(dashboard, true).getAllWidgets().map((wc)=>wc.machineName))).map((widgetMachineName)=>{
                  return {[widgetMachineName]: {...versionData?.data?.outputMapping[widgetMachineName], constrainedViewDateRange: report?.config.dateRange}}
              }).reduce((a,b)=>({...a,...b}), {})}
              onServiceReady={(service) => {
                setDService(service);
              }}
              constrainedViewDateRange={report.config.dateRange}
            />}
        </Container>
    )
}

function buildDefaultInjectionConfig(amNamespace: string, outputMapping: OutputMapping): ExcelReportInjectionConfig{
     // Default Injection Config, which injects in an empty excel. In sheet named "Report", in a top-down fashion.
        let injectionConfig: ExcelReportInjectionConfig = [];
        let lastRowNumWrittenTo= 0;
        for(let [machineName, output] of Object.entries(outputMapping)){
            injectionConfig.push({outputOf: machineName, startCell: `A${lastRowNumWrittenTo+1}`, sheetName: "Report"})
            if('queryResultData' in output){ // +1 to include the header row
                lastRowNumWrittenTo += output.queryResultData.rows.length+1;
            } else if('value' in output){
                lastRowNumWrittenTo += 1;
            }
        }
        return injectionConfig;
    // if(amNamespace === "NRN"){
    //     return [
    //         {"outputOf": "asset-details", "startCell": "A1", "sheetName": "Asset Details"},
    //         {"outputOf": "cash-balance", "startCell": "B22", "sheetName": "<User Input>"},
    //         {"outputOf": "current-facility-amount", "startCell": "B23", "sheetName": "<User Input>"},
    //         {"outputOf": "net-asset-value", "startCell": "B24", "sheetName": "<User Input>"},
    //         {"outputOf": "equity-raise", "startCell": "B25", "sheetName": "<User Input>"},
    //     ];
    // } else if(amNamespace === "PUBLICDEMO") {
    //     return [
    //         {"outputOf": "asset-details", "startCell": "A1", "sheetName": "Asset List"},
    //         {"outputOf": "cash-balance", "startCell": "B22", "sheetName": "Inputs"},
    //         {"outputOf": "current-facility-amount", "startCell": "B23", "sheetName": "Inputs"},
    //         {"outputOf": "net-asset-value", "startCell": "B24", "sheetName": "Inputs"},
    //         {"outputOf": "equity-raise", "startCell": "B25", "sheetName": "Inputs"}
    //     ];
    // } else


    return [];
}