import {
    DEFAULT_SOURCE_GRID,
    LineItemsStore, LineItemsStoreDto,
    ResultOptions,
} from "../../../ps-models/lineitems-store";
import {LoadingBlock} from "../../../ui/Loading";
import {Button, Icon, Input, Segment} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {UploadLineItems} from "../../UploadLineItems";
import {useDashboardService} from "../DashboardConfigService";
import {useInstantUpdater} from "../../../generic.hooks";
import {LineItem} from "../../../ps-models/line-items";
import {useOnConfigChange} from "./commons";

interface ConfigProps {
  lineItemNames: string[],
  grid: ResultOptions['grid']
}

registerWidgetType({
    typeId: 'Excel-Upload',
    metadata: {
      name: 'Upload Line Item Values via excel',
      description: 'Upload Line Item Values via excel',
      icon: 'file excel outline',
    },
    defaultConfig: {
      lineItemNames: [],
      grid: DEFAULT_SOURCE_GRID
    },
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
      return <WidgetConfig
        config={config} context={context} onConfigChange={setConfig}/>
    },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <Widget context={context} setOutput={setOutput}/>
    }
  }
)

function WidgetConfig({config, context, onConfigChange}:
                        { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

  let [localConfig, setLocalConfig] = useState(config);
  const handleApply = () => {
    onConfigChange({...localConfig, lineItemNames: localConfig.lineItemNames.filter((ln)=>ln!=='')});
  }

  const handleDiscard = () => {
    setLocalConfig(config);
  }

  const handleColumnChange = (index: number, liName: string) => {
    const clonedLiNames = [...localConfig.lineItemNames];
    clonedLiNames[index] = liName;
    setLocalConfig({...localConfig, lineItemNames: clonedLiNames});
  }
  const handleAddItem = () => {
        setLocalConfig({...localConfig, lineItemNames: [...localConfig.lineItemNames, '']});
  }

  const handleDeleteItem = (index: number) => {
        setLocalConfig({...localConfig, lineItemNames: localConfig.lineItemNames.slice(0, index).concat(localConfig.lineItemNames.slice(index+1)) });
  }

  return <>
      <p></p>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          Allowed Line Items:
          <Button  size="tiny" icon onClick={handleAddItem}>
              <Icon name='plus' />
          </Button>
      </div>
      {localConfig.lineItemNames.map((liName, index) => <>
              <Segment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      Line Item #{index}
                      <Button size="tiny" icon>
                          <Icon name='trash' onClick={() => handleDeleteItem(index)}/>
                      </Button>

                  </div>
                  <div>
                      <label>Name</label>
                      <Input size="mini"
                             value={liName}
                             onChange={(e, data) =>
                          handleColumnChange(index, data.value)
                      } />
                  </div></Segment>
          </>
      )}
      <p></p>
    <Button size="small" primary onClick={handleApply}>Apply</Button>
    <Button size="small" color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>
}

function Widget({context, setOutput}: { context: BuilderContext,
  setOutput: (key: string, value: any) => void }) {

  let {config, store} = useOnConfigChange<ConfigProps>(context);

  let dsConfig = context.appContext.getConfig()


  if (!store) {
    return <LoadingBlock/>
  }
  const handleLineItemsUploaded = (lineItems: LineItem[])=>{
      for(let li of lineItems){
          dsConfig.addExtraLineItem(li);
      }
  }

    let from = store.timeIndex.startDate;
    let to = store.timeIndex.endDate;
    return <UploadLineItems store={store}
                            lineItemNames={config.lineItemNames}
                            aggregationRange={{from, to}}
                            onQueryResultComputed={(q, r)=>{
                                setOutput("result", r);
                            }}
                            onLineItemsUploaded={handleLineItemsUploaded}
                            resultantTableOptions={{
                                grid: config.grid
                            }}
    />
}