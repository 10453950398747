import {
    applyUpdateToStoreWithSites,
    calcBatchEditableFields, calcBatchEditableFormulas,
    DIFFERENCES_CONSTANT,
    LineItemBatch, PartiallyCommonField,
    SiteLineItem, updateRemoteStoreFromStoreWithSites,
} from "./lineItemBatchOperations";
import {LineItemsFieldSet, TimedCalculatedLineItem} from "../../ps-models/line-items";
import {
    BatchFieldsEditor,
    FieldEditorState,
    makeInitialFieldEditorState,
} from "./BatchFieldsEditor";
import {Button, Dimmer, Header, Icon, Loader, Popup, Segment} from "semantic-ui-react";
import React, {useMemo, useState} from "react";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {useInstantUpdater} from "../../generic.hooks";
import {ConfirmChanges, ConfirmSavePopup} from "../../ui/ConfirmSavePopup";
import {ConfirmDiscardChangesPopup} from "../../ui/ConfirmDiscardChangesPopup";
import {usePopup} from "../../ui/Popup";
import {ErrorPopup} from "../../ui/ErrorPopup";
import {BatchFormulaEditor, FormulaEditorState, makeInitialFormulaEditorState} from "./BatchFormulaEditor";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING, useAssetManagementModule} from "../index";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";

export function BatchEditor({lineItemName, siteLineItems, store, onClose}: LineItemBatch & {onClose: () => void}): React.JSX.Element {
    const [updateId, updateComponent] = useInstantUpdater()
    const company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);
    const module = useAssetManagementModule();
    const storeSetup = MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING[module.Name] || MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"];
    if(!storeSetup){
        console.warn(`The store setup was not found for module ${module.Name}, using the one for DEMO`)
    }

    const siteCalculatedLineItems = useMemo(() => siteLineItems.filter(({liName})=> {
        const li = store.getDataSet().getLineItem(liName);
        return li instanceof TimedCalculatedLineItem;
    }), [siteLineItems, store, updateId])
    const [initialFields, partiallyCommonFields, differences] = useMemo(
        () => calcBatchEditableFields(siteLineItems, store), [siteLineItems, store, updateId]
    )
    const [initialFormula, formulaDifferences] = useMemo(
        () => calcBatchEditableFormulas(siteCalculatedLineItems, store), [siteCalculatedLineItems, store, updateId]
    )
    const [fieldEditorState, setFieldEditorState] = useState<FieldEditorState>(makeInitialFieldEditorState)
    const [formulaEditorState, setFormulaEditorState] = useState<FormulaEditorState>(makeInitialFormulaEditorState)
    const { closePopup, openPopup } = usePopup()
    const [isSaving, setIsSaving] = useState(false)
    const canSave = fieldEditorState.canSave || formulaEditorState.canSave;
    async function update(updates: LineItemBatchUpdates) {
        setIsSaving(true)
        try {
            applyUpdateToStoreWithSites(store, updates)
            updateComponent()
            await updateRemoteStoreFromStoreWithSites(collection, storeSetup, lineItemName, store, siteLineItems, {
                updateFields: !!updates?.updateToFields,
                updateFormula: !!updates?.updateToFormula
            });
        } catch(e) {
            console.error(e)
            openPopup(<ErrorPopup header={'Error while updating fields!'} e={e} onClose={closePopup} />)
        }
        setIsSaving(false)
    }

    function handleClose() {
        if (fieldEditorState.modified || formulaEditorState.modified) {
            openPopup(<ConfirmDiscardChangesPopup onDiscard={onClose} onClose={closePopup} />)
        } else {
            onClose()
        }
    }

    function handleSave() {
        const updates = {
            updateToFields: fieldEditorState.canSave ? {
                updatedFields: LineItemsFieldSet.fromList(fieldEditorState.fields),
                deleted: fieldEditorState.deleted.map(f => f.name),
                siteLineItems
            } : undefined,
            updateToFormula: formulaEditorState.canSave ? {
                formula: formulaEditorState.formula,
                siteCalculatedLineItems
            } : undefined
        }
        openPopup(<ConfirmSavePopup
            changes={buildChangesSnapshot(store, updates)}
            onClose={closePopup}
            onSave={() => update(updates)}
        />)
    }
    return (
        <Segment style={{padding: "20px", width: "800px"}}>
            {isSaving && <Saving />}
            <HeaderSection
                lineItemName={lineItemName}
                siteLineItems={siteLineItems}
                onClose={handleClose}
                canSave={canSave && !isSaving}
                onSave={handleSave} />
            {initialFields && <BatchFieldsEditor
                initialData={initialFields}
                differences={differences}
                onStateChanged={setFieldEditorState}
            />}
            {partiallyCommonFields.length > 0 && <PartiallyCommonFieldsSection fields={partiallyCommonFields} />}
            {siteCalculatedLineItems.length > 0 && <>
                <BatchFormulaEditor
                    lineItemName={lineItemName}
                    siteCalculatedLineItems={siteCalculatedLineItems}
                    initialFormula={initialFormula}
                    differences={formulaDifferences}
                    onStateChanged={setFormulaEditorState}
            /></>}
        </Segment>
    );
}


function buildChangesSnapshot(store: LineItemsStore, updates: LineItemBatchUpdates): ConfirmChanges {
    const changes: Record<string, {header: React.JSX.Element, messages: React.JSX.Element[]}> = {}
    if(updates?.updateToFields) {
        const {updatedFields, deleted, siteLineItems} = updates.updateToFields;
        for (const sli of siteLineItems) {
            changes[sli.id] = {header: <strong>{sli.label} ({sli.id})</strong>, messages: []}
            for (const field of deleted) {
                changes[sli.id].messages.push(<>Delete field <strong style={{'color': 'red'}}>{field}</strong></>)
            }

            for (const field of updatedFields.getFields()) {
                if (field.value === DIFFERENCES_CONSTANT) {
                    continue
                }
                const storedField = store.getDataSet().getLineItem(sli.liName).fields.getField(field.key)
                if (storedField === undefined) {
                    changes[sli.id].messages.push(<>Add field <strong style={{'color': 'green'}}>{field.name}</strong> with value <u style={{'color': 'green'}}>{field.value}</u> and label <u style={{'color': 'green'}}>{field.label}</u></>)
                } else {
                    if (storedField.value !== field.value) {
                        changes[sli.id].messages.push(<>Add field <strong>{field.name}</strong> value: <strong style={{'color': 'red'}}>{storedField.value}</strong> <Icon name="arrow right" /> <strong style={{'color': 'green'}}>{field.value}</strong></>)
                    }
                    if (storedField.label !== field.label) {
                        changes[sli.id].messages.push(<>Add field <strong>{field.name}</strong> label: <strong style={{'color': 'red'}}>{storedField.label}</strong> <Icon name="arrow right" /> <strong style={{'color': 'green'}}>{field.label}</strong></>)
                    }
                }
            }
        }
    }

    if(updates?.updateToFormula){
        const {siteCalculatedLineItems, formula: updatedFormula} = updates.updateToFormula;
        for (const sli of siteCalculatedLineItems){
            if(!changes[sli.id]){
                changes[sli.id] = {header: <strong>{sli.label} ({sli.id})</strong>, messages: []}
            }
            const previouslyStoredFormula = (store.getDataSet().getLineItem(sli.liName) as TimedCalculatedLineItem).serialize().fn
            changes[sli.id].messages.push(<>Updating formula: <strong style={{'color': 'red'}}>{previouslyStoredFormula}</strong> <Icon name="arrow right" /> <strong style={{'color': 'green'}}>{updatedFormula}</strong></>)
        }
    }
    return changes
}

interface HeaderSectionProps {
    lineItemName: string;
    siteLineItems: SiteLineItem[];
    onClose: () => void;
    canSave: boolean,
    onSave: () => void
}

function HeaderSection({ lineItemName, siteLineItems, onClose, onSave, canSave }: HeaderSectionProps): React.JSX.Element {
    return (
        <Segment>
            <strong>Name</strong> "{lineItemName}" <strong>in</strong> {siteLineItems.length} sites
            <Popup trigger={<u>(*)</u>}>
                {siteLineItems.map(({id, label}) => <div key={id}>{label} ({id})</div>)}
            </Popup>
            <Button floated="right" size="mini" onClick={onClose}>X</Button>
            <Button floated="right" size="mini" onClick={onSave} disabled={!canSave}>Apply Changes</Button>
        </Segment>
    );
}



function PartiallyCommonFieldsSection({ fields }: {fields: PartiallyCommonField[]}): React.JSX.Element {
    return <Segment>
        <Header>Fields that are not common to all sites</Header>
        {fields.map(({fieldName, occurrences}) => <div
            key={fieldName}>{fieldName} ({occurrences} sites)</div>)}
    </Segment>
}

function Saving() {
    return <Dimmer page={false} active={true} inverted><Loader active={true} content={`Saving, Please wait...`}/></Dimmer>
}

export type LineItemBatchUpdates = {updateToFields?: FieldUpdatesData, updateToFormula?: FormulaUpdatesData};
type FormulaUpdatesData =  {formula: string, siteCalculatedLineItems: SiteLineItem[]};
type FieldUpdatesData =  {updatedFields: LineItemsFieldSet, deleted: string[], siteLineItems: SiteLineItem[]};
