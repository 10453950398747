import {BuilderContext} from "../WidgetRegistry";
import {useInstantUpdater} from "../../../generic.hooks";
import {useEffect} from "react";
import {LineItemsStore} from "../../../ps-models/lineitems-store";


export function getGlobalContext(context: BuilderContext): any {
  return context.appContext.globalContext
}

export function getStore(context: BuilderContext): LineItemsStore {
  return context.store || context.appContext.getStore();
}

export function getConfig(context: BuilderContext): any {
  return context.appContext.getWidgetConfig(context.id);
}

export function useOnConfigChange<T>(context: BuilderContext): {config: T, store: LineItemsStore} {
  const widgetId = context.id;
  const [, update] = useInstantUpdater();
  const config = context.appContext.getWidgetConfig(widgetId);
  let store = context.store;

  useEffect(() => {
    context.appContext.onConfigChanged(widgetId, update);
  }, []);

  return {config, store}
}

export function useOnStoreReady(context: BuilderContext) {
  const [, update] = useInstantUpdater();

  useEffect(() => {
    context.appContext.onStoreReady(()=>{
      update()
    });
  }, []);
}

export function useOnStoreLoading(context: BuilderContext) {
  const [, update] = useInstantUpdater();


  useEffect(() => {
    context.appContext.onStoreLoading(()=>{
      update()
    });
  }, []);
}


export function useUpdateOnQueryChange(context: BuilderContext) {
  const [, update] = useInstantUpdater();
  useEffect(() => {
    context.appContext.onQueryChanged(update)
  }, []);
}

export function useUpdateOnGlobalContextChange(context: BuilderContext, callback: () => void = ()=>{}) {
  const [, update] = useInstantUpdater();
  useEffect(() => {
    context.appContext.onGlobalContextChanged(()=>{
      update();
      callback();
    })
  }, []);
}

export function useUpdateOnCompareWithStoreIdsChange(context: BuilderContext) {
  const [, update] = useInstantUpdater();
  useEffect(() => {
     // console.info("PROC: useUpdateOnCompareWithStoreIdsChange")
     // context.appContext.onCompareWithStoreIdsChanged(update)
  });
}

