import React, {useCallback, useMemo, useState} from 'react';
import {Button, Container, Grid, Header, Select, Tab} from "semantic-ui-react";
import {apiUrl} from "../psPlatformClient";
import {getSalesforceObjects, syncLineItems, syncObjectsOfType, syncSiteDER} from "./boards/demo/assetsManagement.client";
import {Alert} from "@mui/material";
import {useCompany, useCompanyId, useCurrentUser} from "../core";
import {DateRange} from "../lineitems-store/DateRange";
import {useAllSites} from "./siteHooks";
import {MultiSelect, MultiSelectOption} from "../ui/MultiSelect";
import {FileImport} from "./integrations/fileImport";
import {authStorage} from "../auth";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING, useAssetManagementModule} from "./index";
import {ProcessLogs} from "../ui/ProcessLogs";
import {getAmProjectConfig} from "../ps-models";
export function Integration() {
  let companyName = useCompany().name;
  const module = useAssetManagementModule();
  const storeSetup = MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING[module.Name] || MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"];
  if(!storeSetup){
    console.warn(`The store setup was not found for module ${module.Name}, using the one for DEMO`)
  }

  return (
    <Container

      header={"Integration"}>
      <Header>
                                                                             {companyName}
      </Header>
      <Tab
        panes={[
          { menuItem: 'Salesforce', render: () => <Tab.Pane>{<SalesforceIntegration />}</Tab.Pane> },
          { menuItem: 'DER', render: () => <Tab.Pane>{<DERIntegration />}</Tab.Pane> },
          { menuItem: 'File Import', render: () => <Tab.Pane>{<FileImport storeSetup={storeSetup}/>}</Tab.Pane> },
          { menuItem: 'Sync LineItems', render: () => <Tab.Pane>{<LineItemSync />}</Tab.Pane> },
        ]}

          />

    </Container>
  );
}

function useSiteOptions(demarcateWhetherSiteIsNew: boolean = false) {

  const sites = useAllSites(demarcateWhetherSiteIsNew) || [];
  const siteOptions = useMemo(() => sites.map(({id, label, isNew}) => ({
    key: id,
    value: id,
    text: label,
    ...(isNew !==undefined ? {group: (isNew === true ? "new": "existing")} : {})
  })), [sites]);
  const [selectedSiteOptions, setSelectedSiteOptions] = useState<MultiSelectOption[]>(siteOptions);


  let multiSiteSelectComponent = <MultiSelect
    options={siteOptions}
    onChanged={setSelectedSiteOptions}
    initialSelection={selectedSiteOptions}
  />

  return {selectedSites: selectedSiteOptions.map(o => o.value), multiSiteSelectComponent};
}



function LineItemSync() {
  const companyId = useCompanyId();
  let company = authStorage.getCompany();
  const {collection} = getAmProjectConfig(company);
  const [syncStatus, setSyncStatus] = useState<string[]>([]);
  const [processLogs, setProcessLogs] = useState<{warning: string[], info: string[]} | null>(null);

  const {selectedSites, multiSiteSelectComponent} = useSiteOptions(true);

  const handleSync = useCallback(async () => {
    setProcessLogs(null);
    for(let i = 0; i < selectedSites.length; i++) {
      const site = selectedSites[i];
      try {
        setSyncStatus(st=> st.concat(` ${site} Syncing`));
        const siteSyncProcessLog = await syncLineItems(companyId, site.toString(), collection);
        setProcessLogs((prev)=>{
          if(!prev){
            if(siteSyncProcessLog.info.length >0 && siteSyncProcessLog.warning.length > 0){
              return siteSyncProcessLog;
            } else {
              return null;
            }
          } else {
            return {info: [...prev.info, ...siteSyncProcessLog.info], warning: [...prev.warning, ...siteSyncProcessLog.warning]}
          }
        });
        setSyncStatus(st=> st.concat(` ${site} Synced`));
      } catch (err) {
        console.error(site, err);
        setSyncStatus(st=> st.concat(` ${site} Error`));
      }
    }
  }, [selectedSites]);

  return <div>
    {processLogs && <ProcessLogs logs={processLogs} maxHeight={'250px'} />}
    <Grid>
      <Grid.Row columns={3}>
      <Grid.Column>
        <div>
          <strong>Line Items:</strong>
          {multiSiteSelectComponent}
        </div>
      </Grid.Column>
        <Grid.Column>
          <div>
            <strong>System:</strong> <Select size="small" value="all"
                                             options={[{key: "all", value: "all", text: "Salesforce"}]} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Button  onClick={handleSync}>Configure Scheduled Sync</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>




    <div style={{marginTop: 15}}>
    <Button primary onClick={handleSync}>Sync Line Items</Button>
    </div>

    <hr />
    <h3>Status Log</h3>
    {syncStatus.map((status) => <div>{status}</div>)}
  </div>
}

function DERIntegration() {

  const {selectedSites, multiSiteSelectComponent} = useSiteOptions();

  const [syncStatus, setSyncStatus] = useState<string[]>([]);

  // let today = new Date(2025, 8, 1);
  // let jan1 = new Date(2022, 11, 1);
  let today = new Date();
  let jan1 = new Date(today.getFullYear(), 0, 1);

  const [startDate, setStartDate] = useState(jan1);
  const [endDate, setEndDate] = useState(today);
  const companyId = useCompanyId();
  const company = authStorage.getCompany();
  const { collection } = getAmProjectConfig(company);

  const handleSync = useCallback(async () => {
    for(let i = 0; i < selectedSites.length; i++) {
      const site = selectedSites[i];
      try {
        setSyncStatus((st) => st.concat([` ${site} Syncing`]));
        await syncSiteDER(companyId, site.toString(), startDate, endDate, collection)

        setSyncStatus(st => st.concat([` ${site} Synced`]));
      } catch (err) {
        console.error(site, err);
        setSyncStatus(st => st.concat([` ${site} Error`]));
      }
    }
  }, [selectedSites]);


  return <div>
    {multiSiteSelectComponent}
    <DateRange initialStartDate={jan1} initialEndDate={today} onSelect={(startDate, endDate)=>{
        setStartDate(startDate);
        setEndDate(endDate);
    }} />
    <Button onClick={handleSync}>Sync Device Data </Button>
    <h3>Status Log</h3>
    {syncStatus.map((status) => <div>{status}</div>)}
  </div>
}

function SalesforceIntegration() {

  const [objectTypes, setObjectTypes] = useState([] as any[]);
  const [objectTypesSyncStatus, setObjectTypesSyncStatus] = useState("" as string);

  const [selectedObjectType, setSelectedObjectType] = useState("" as string);
  const [syncMessage, setSyncMessage] = useState("" as string);
  const currentUser = authStorage.getUser();
  const companyId = useCompanyId();
  const company = authStorage.getCompany();
  const { collection } = getAmProjectConfig(company);

  const initiateOAuth = () => {
    // Prepare the URL and redirect the user to Salesforce
    const url = `${apiUrl(currentUser.company?.region ?? null)}/integrations/salesforce/connect`
    window.location.href = url;
  };

  const handleLoadSalesforceObjects = () => {
    setObjectTypesSyncStatus("Loading...");
    getSalesforceObjects().then((objects) => {
      setObjectTypesSyncStatus("Loaded");
      setObjectTypes(objects);
    }).catch((err) => {
      setObjectTypesSyncStatus("Error");
    });
  }

  const handleSync = () => {
    setSyncMessage("Syncing...");
    syncObjectsOfType(companyId, selectedObjectType, collection)
      .then((res) => setSyncMessage("Sync Done"))
      .catch((err) => setSyncMessage("Sync Failed"));
  }

  return <div>
    <Button onClick={initiateOAuth}>Log in with Salesforce</Button>
    <hr />
    <Button onClick={handleLoadSalesforceObjects}>Load Salesforce Objects</Button>
    Select Object:
    {objectTypesSyncStatus === "Loaded" && <Select
      onChange={(e, data) => setSelectedObjectType(data.value as string)}
      search={true} options={objectTypes.map((object) => { return {key: object.name, value: object.name, text: `${object.label} | ${object.name}`}})} />}

    {objectTypesSyncStatus !== "Loaded" && objectTypesSyncStatus}

    {syncMessage && <Alert severity="info">{syncMessage}</Alert>}

    {selectedObjectType && <p>
    Synchronize Site:<Button onClick={() => handleSync()}>Syncronize</Button></p>}

  </div>
}