import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    List,
    Message,
    Segment,
    Select,
    SemanticWIDTHS,
    Table
} from "semantic-ui-react";
import {usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {
  CANONICAL_NAME_FIELD, LineItemsStore,
  pQuery, QueryResult, SOURCE_NAME_FIELD,
  StoreQuery, TimeColumn
} from "../../../ps-models/lineitems-store";
import React, {ReactNode, useEffect, useState} from "react";
import {SPVSelection} from "./SPVSelection";
import {
    compareNormalized,
    formatDateByTimeUnit,
    getLastDateOfPreviousMonth,
    TimeUnits
} from "../../../ps-models";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {loadSiteStore} from "../../siteStoreLoader";
import {LoadingBlock} from "../../../ui/Loading";
import {values} from "ramda";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import {addCustomLabelToItemsInStore, setLabelAndFormatForAggregates} from "./storeFormulas";
import {RevenueVsLoanCommitments} from "./RevenueVsLoanCommitments";
import {NRNTimeSeriesChart} from "./NRNTimeSeriesChart";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING} from "../../index";
import {useCompanyId} from "../../../core";
import {useHistory} from "react-router-dom";
import {StaticPDFPreview} from "../../../ui/StaticPDFPreview";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";


export function LoanManagement() {

  let [spv, setSpv] = useState<string>();

  let spvContent: Record<string, ReactNode> = {
      'NRN AC NO23': <NrnACNo23 />,
     'NRN AC NO1': <NrnACNo1 />,
     'NRN AC NO3': <NrnACNo3 />
  }

  return <Segment>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{verticalAlign: 'middle'}}>
              {!spv ? "Please select an SPV" : ""}
          </div>
    <div>
      <SPVSelection onSelect={setSpv} />
    </div>
    </div>
    {spv && spvContent[spv]}

  </Segment>
}

export function IncomingPayments({store, filter}: {store: LineItemsStore, filter: StoreQuery}) {

  let result = store.query(StoreQuery
    .byNames(['Interest Payment', 'Principal Repayment'])
    .and(StoreQuery.withField('lender'))
    .or(filter)
  );

  let nextPayments: { date: Date, value: string, paymentType: string, lender: string }[] = [];
  for(let row of result.rows) {
    for(let paymentType of ["Interest Payment", "Principal Repayment"]) {
        if (row.name.text.toLowerCase().endsWith(paymentType.toLowerCase())) {
          for (let cell of values(row)) {
              const lenderCell = row["fields.lender"];
              if(!lenderCell){
                  continue;
              }
            let timeCol = (result.columnById(cell.columnId) as TimeColumn);
            let today = new Date().getTime();
            if (timeCol && timeCol.time && today < timeCol.time && cell.value !== 0) {
              nextPayments.push({
                date: new Date(timeCol.time),
                value: cell.text,
                paymentType,
                lender: lenderCell.text
              });
              break;
            }
          }
        }
      }
  }

  // sort by date
  nextPayments.sort((a, b) => a.date.getTime() - b.date.getTime());


  return (<div>
    <Message warning style={{marginTop: "15px", marginBottom: "15px"}}>
      <div><strong>Upcoming Loan Payments</strong></div>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Payment Type</Table.HeaderCell>
            <Table.HeaderCell>Next Payment Date</Table.HeaderCell>
            <Table.HeaderCell>Lender</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
        <Table.Body>
          {nextPayments.map((p, i) => (<Table.Row>
            <Table.Cell>{p.paymentType}</Table.Cell>
            <Table.Cell>{formatDateByTimeUnit(p.date, "days")}</Table.Cell>
            <Table.Cell>{p.lender}</Table.Cell>
            <Table.Cell>{p.value}</Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Message></div>
  );
}


export function NrnACNo23() {

  let [spvStore, setSpvStore] = useState<LineItemsStore>();
  let [granularity, setGranularity] = useState<TimeUnits>("months");
  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);
  let parameters = [
    'SPV_Fund__c'
  ]

  let aggregatedStore = usePersistenceStoreQueryWithContextUpdate(collection,
      pQuery()
          .selectSourceParams(parameters)
          .havingParameterValues({"SPV_Fund__c": "NRN AC NO23"})
          .withLineItems(['Net Revenue', 'Current Value'])
          .withStoreType('document')
          .withGranularity('months')
      , (store) => {

          store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD,
            {
              defaultGroupingOperation: "sum"
            });
          setLabelAndFormatForAggregates(store);
      }
  );

  useEffect(() => {
    if(!aggregatedStore) {
      return;
    }
    loadSiteStore('nrn-ac-no23', collection, MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["NRN"]).then(store => {
      if(store && aggregatedStore){
        const materializedAggregatedStore = aggregatedStore.materializeTimed(StoreQuery.all(), {}, true)
        const netRevenueLis = materializedAggregatedStore.getDataSet().getByCanonicalName('Net Revenue');
        const currentValueLis = materializedAggregatedStore.getDataSet().getByCanonicalName('Current Value');
        store.getDataSet().addLineItems([...netRevenueLis, ...currentValueLis])
      }
      setSpvStore(store!)
    })
  }, [aggregatedStore]);

    let [filterQuery, filtersComponent] = useLineItemFilter({
        'lender': 'Lender',
    }, spvStore);

  if(!spvStore) return <LoadingBlock />;

    addCustomLabelToItemsInStore(spvStore, 'Drawn Balance', 'Funds Under Management', 'lender');
    addCustomLabelToItemsInStore(spvStore,'Current Value', 'Value of Assets')

    const lineItemNamesForTableView = ['Drawn Balance', 'Principal Repayment', 'Interest Payment', 'Withholding Tax', 'Interest Payment After Tax', 'Net Revenue'];

    const allLineItemNames = [...lineItemNamesForTableView, 'Annual Interest Rate', 'Current Value'];
    const spvMaterializedStore = spvStore
        .materializeTimed(StoreQuery.byNames(allLineItemNames)
            .or(filterQuery)
            .withTimeIndex(spvStore.timeIndex.withGranularity(granularity))
        )
    let spvResult = spvMaterializedStore.query(StoreQuery.byNames(allLineItemNames, true));
    let spvResultForTableView = spvMaterializedStore.query(StoreQuery.byNames(lineItemNamesForTableView, true));


    let borrowers = [
    <>
      <strong>Lender 1:</strong> Vican <br/>
      {/*<strong>Total Debt Amount:</strong> $10,000 <br/>*/}
    </>,
    <>
      <strong>Lender 2:</strong> Karovel <br/>
      {/*<strong>Total Debt Amount:</strong> $200,000 <br/>*/}
    </>
  ]

  return <div>
        <Header>NRN AC No 23 Pty Ltd</Header>

        {filtersComponent}
        <p><strong>Borrower:</strong> NRN AC No 23 Pty Ltd, a related body corporate of NRN Holdings Pty Ltd (ACN 668 183 819) </p>
        <p><strong>Total Debt Amount Drawn till date:</strong> $ 300,000 </p>
        <p><strong>Annual Interest Rate:</strong>  {spvResult.firstTimeSlotTextOf("Annual Interest Rate")}   </p>
        <p>
            Interest is accrued & calculated daily against the drawn down balance of the loan less any accumulated Loan Repayments on each calendar day until the last calendar day of each month.
        </p>
        <p>
            <strong>Loan Repayment Schedule:</strong> <br/>
            <ul>
                <li>50% to be amortised and repaid in 24 equal monthly installments due on the monthly anniversary of the drawdown.</li>
                <li> 50% will be repaid in a single repayment on the last calendar day of the 24th month of the loan term for the drawdown.</li>
            </ul>
            <i>Cash Collateral Requirement: 10% of drawn down balance</i> </p>
        <p>
            <i>The SPV has Principal repayment and Interest payment obligations every month.</i>
        </p>
        <hr/><br/>
        <Grid>
            <Grid.Row divided columns={borrowers.length as SemanticWIDTHS}>
              {borrowers.map((b, i) => <Grid.Column key={i} width={8}>{b}</Grid.Column>)}
            </Grid.Row>
        </Grid>

      {spvStore && <IncomingPayments store={spvStore} filter={filterQuery} />}

    {spvResultForTableView && <>
        <Select
            placeholder='Select granularity'
            value={granularity}
            options={[
                { key: 'months', value: 'months', text: 'Month' },
                { key: 'years', value: 'years', text: 'Year' },
            ]}
            onChange={(e, {value}) => {
                setGranularity(value as TimeUnits);
            }}
        />
        <LineItemsTableWithFormulas queryResult={spvResultForTableView} store={spvStore}  withGroups projectionsStartDate={new Date(getLastDateOfPreviousMonth())} /></>}

    {spvResult && <RevenueVsLoanCommitments queryResult={spvResult} />}
    {/*{spvResult && <FUMvsVOAChart queryResult={spvResult} />}*/}

    </div>

}

export function NrnACNo1() {

  let [spvStore, setSpvStore] = useState<LineItemsStore>();
  let [granularity, setGranularity] = useState<TimeUnits>("months");

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);
  let parameters = [
        'SPV_Fund__c'
    ]

    let aggregatedStore = usePersistenceStoreQueryWithContextUpdate(collection,
        pQuery()
            .selectSourceParams(parameters)
            .havingParameterValues({"SPV_Fund__c": "NRN AC NO1"})
            .withLineItems(['Net Revenue', 'Current Value'])
            .withStoreType('document')
            .withGranularity('months')
        , (store) => {

            store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD,
              {
                defaultGroupingOperation: "sum"
              });
            setLabelAndFormatForAggregates(store);
        }
    );

    useEffect(() => {
        if(!aggregatedStore) {
            return;
        }
        loadSiteStore('nrn-ac-no1', collection, MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["NRN"]).then(store => {
            if(store && aggregatedStore){
                const materializedAggregatedStore = aggregatedStore.materializeTimed(StoreQuery.all(), {}, true)
                const netRevenueLis = materializedAggregatedStore.getDataSet().getByCanonicalName('Net Revenue');
                const currentValueLis = materializedAggregatedStore.getDataSet().getByCanonicalName('Current Value');
                store.getDataSet().addLineItems([...netRevenueLis, ...currentValueLis])
            }
            setSpvStore(store!)
        })
    }, [aggregatedStore]);


  let [filterQuery, filtersComponent] = useLineItemFilter({
    'lender': 'Lender',
  }, spvStore);

  if(!spvStore) return <LoadingBlock />;

  addCustomLabelToItemsInStore(spvStore, 'Drawn Balance', 'Funds Under Management', 'lender');
  addCustomLabelToItemsInStore(spvStore,'Current Value', 'Value of Assets')

  const lineItemNamesForTableView = ['Drawn Balance', 'FUM', 'Principal Repayment', 'Interest Payment', 'Withholding Tax', 'Interest Payment After Tax', 'Net Revenue'];
  const allLineItemNames = [...lineItemNamesForTableView, 'Annual Interest Rate', 'Current Value'];
  const spvMaterializedStore = spvStore
    .materializeTimed(StoreQuery.byNames(allLineItemNames)
        .or(filterQuery)
        .withTimeIndex(spvStore.timeIndex.withGranularity(granularity))
    )
  let spvResult = spvMaterializedStore.query(StoreQuery.byNames(allLineItemNames, true));
  let spvResultForTableView = spvMaterializedStore.query(StoreQuery.byNames(lineItemNamesForTableView, true));


  let borrowers = [
    <>
      <strong>Lender 1:</strong> Kinsey Cotton <br/>
      {/*<strong>Total Debt Amount:</strong> $250,000 <br/>*/}
      {/*<strong>Additional Terms:</strong> Withholding Tax: 10%*/}
    </>,
    <>
      <strong>Lender 2:</strong> Jaqui Courtney <br/>
      {/*<strong>Total Debt Amount:</strong> $100,000 <br/>*/}
    </>,
    <>
      <strong>Lender 3:</strong> Most Beautiful Trust <br/>
      {/*<strong>Total Debt Amount:</strong> $250,000 <br/>*/}
    </>
  ]

  return <div>
    <Header>NRN AC No 1 Pty Ltd</Header>

    {filtersComponent}
    <p><strong>Borrower:</strong> NRN AC No 1 Pty Ltd, a related body corporate of NRN Holdings Pty Ltd  </p>
    <p><strong>Total Debt Amount Drawn till date:</strong> $ 600,000 </p>

    <p><strong>Annual Interest Rate:</strong> {spvResult.firstTimeSlotTextOf("Annual Interest Rate")} </p>
    <p>
      Interest is accrued & calculated daily against the drawndown balance of the loan less any accumulated Loan Repayments on each calendar day until the last calendar day of each month.    </p>
    <p>
      <strong>Loan Repayment Schedule:</strong> <br/>
      <ul>
        <li>50% to be amortised and repaid in 24 equal monthly installments due on monthly anniversary of the drawdown.</li>
        <li>50% will be repaid in a single repayment on the last calendar day of the 24th month of the loan term for the drawdown.</li>
      </ul>
      <i>Cash Collateral Requirement: 10% of drawn down balance</i> </p>
    <p>
      <i>The SPV has Principal repayment and Interest payment obligations every month.</i>
    </p>
    <hr/><br/>
    <Grid>
      <Grid.Row divided columns={borrowers.length as SemanticWIDTHS}>
        {borrowers.map((b, i) => <Grid.Column key={i}>{b}</Grid.Column>)}
      </Grid.Row>
    </Grid>

      {spvStore && <IncomingPayments store={spvStore} filter={filterQuery} />}
      {spvResultForTableView && <>
          {/* @TODO: Convert this to a hook like component and reuse... */}
        <Select
            placeholder='Select granularity'
            value={granularity}
            options={[
                { key: 'months', value: 'months', text: 'Month' },
                { key: 'years', value: 'years', text: 'Year' },
            ]}
            onChange={(e, {value}) => {
                setGranularity(value as TimeUnits);
            }}
        />
        <LineItemsTableWithFormulas queryResult={spvResultForTableView} store={spvStore}  withGroups projectionsStartDate={new Date(getLastDateOfPreviousMonth())}/>
      </>}
      {spvResult && <NRNTimeSeriesChart title={"Revenue vs Loan Commitments"} result={spvResult}
                           options={{
                               stackingConfig: {},
                               includeLineItems: [{name: 'Net Revenue', type: 'line'},
                                   {name: 'Principal Repayment', type: 'bar'},
                                   {name: 'Withholding Tax', type: 'bar'},
                                   {name: 'Interest Payment After Tax', type: 'bar'},
                               ],
                           }}/>}
      {/*{spvResult && <FUMvsVOAChart queryResult={spvResult} />}*/}

  </div>

}

export function NrnACNo3() {
    const companyId = useCompanyId();
    const history = useHistory();

    return <div>
        <Header>NRN AC No 3 Pty Ltd</Header>
        <div style={{display:'flex', flexDirection: 'column', gap: '10px'}}>
            <Button style={{alignSelf: 'flex-end'}} size="medium" primary onClick={() => history.push(`/ps/${companyId}/am/reports`)}>Borrowing Base Reports</Button>
            <StaticPDFPreview fileName={'Project Numerian Term Sheet - Signed.pdf'} />
        </div>

    </div>

}

function FUMvsVOAChart({queryResult}: { queryResult: QueryResult }){
    return  <NRNTimeSeriesChart title={"Funds Under Management vs Value of Assets"} result={queryResult}
                                                   options={{
                                                       includeLineItems: ['Drawn Balance', 'Current Value']}}/>
}