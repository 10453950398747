import {Grid} from "semantic-ui-react";
import {MultiSelect} from "../../ui/MultiSelect";
import {buildQueryFromFilters, ScenarioContext} from "./ScenarioFilter.component";
import {AmProject, AmScenario, FiltersDetails} from "../../ps-types";
import {useCompanyId} from "../../core";
import {ScenarioFilters} from "../../../components/Filter/Filter.component";
import {useDashboardService} from "../builder/DashboardConfigService";


interface AmScenarioFilterSectionProps {
  scenarioTitle?: string;
  selectedContextInternal: ScenarioContext;
  setScenarioTitle: (string: string) => void;
  selectedScenario: string;
  setSelectedContextInternal: (arg: ScenarioContext) => void;
  filtersMap: AmScenario["filtersMap"];
  paramsMap: AmScenario["paramsMap"];
  projectOptions: Array<any>;
  setProjects: (projects: AmProject[]) => void;
  setClearButtonDisabled: (bool: boolean) => void;
}

export function AmScenarioFilterSection({
  selectedScenario,
  setSelectedContextInternal,
  setScenarioTitle,
  filtersMap,
  paramsMap,
  selectedContextInternal,
  scenarioTitle,
  projectOptions,
  setProjects,
  setClearButtonDisabled,
}: AmScenarioFilterSectionProps) {

  let companyId = useCompanyId();
  const { getService } = useDashboardService();
  const dService = getService();

  const handleChangeScenario = (value: string[]) => {
    const filterDetails: FiltersDetails = {
      filtersMap: filtersMap as AmScenario["filtersMap"] || {},
      initialFiltersMap: dService.getGlobalContext()?.filterDetails?.initialFiltersMap,
      filterQuery: buildQueryFromFilters(filtersMap),
    };
    setSelectedContextInternal({ storeIds: value, filterDetails, paramsMap });
    setScenarioTitle("Custom Selection");
    setClearButtonDisabled(false);
  };
  return (
    <>
      <Grid.Row>
        <Grid.Column style={{ display: "flex" }}>
          {dService.getConfig().isProjectVersionSelectorExposed() && (
          <MultiSelect
            placeholderText={`${scenarioTitle}`}
            hideSelectAll
            hideSelectByGroup
            options={projectOptions}
            initialSelection={projectOptions.filter((o: any) =>
              selectedContextInternal.storeIds.includes(o.value as string)
            )}
            onApply={(selectedOptions) =>
              handleChangeScenario(
                selectedOptions.map((o) => o.value as string)
              )
            }
            disableApplyOnNoSelection={true}
          />
          )}
          <ScenarioFilters />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}


