import { DataGridPro} from '@mui/x-data-grid-pro';
import React, {useEffect, useState} from "react";
import {ProjectToSync} from "../../ps-types";
import {useCompanyId} from "../../core";
import {ClosableSection} from "../../ClosableSection";
import {CodeEditor} from "../../ui/CodeEditor";
import {SmallLoading} from "../../ui/Loading";
import {Button, Header, Segment} from "semantic-ui-react";
import {getProjectsToSync, syncProjects} from "./amProjectSync.client";
import {useMessages} from "../../ui/MessagesProvider";
import {GridToolbar} from "@mui/x-data-grid";
import {DateRange} from "../../lineitems-store/DateRange";
import {addMonths} from "../../ps-models";



export function ProjectSync() {

  const [projectsToSync, setProjectsToSync] = useState<ProjectToSync[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const companyId = useCompanyId();
  const [selected, setSelected] = useState<string[]>([]);
  const {info, error} = useMessages();
  const [startDate, setStartDate] = useState<Date>(addMonths(new Date('2023-01-01'), -3));
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    getProjectsToSync(companyId).then((res) => {
      setProjectsToSync(res)
      setLoading(false)
    })
  }, []);

  //Projects map to Table for MUIX GRid
  const rows = projectsToSync.map((project) => {
    return {
      id: project.projectId,
      ...project
    }
  });

  const columns = Object.keys(rows[0] || {}).map((key) => {
    return {
      field: key,
      headerName: key,
      width: 150
    }
  });


  const projectIds: string[] = []
  const query = `
  SELECT
              acx.CONTRACTID as projectId,
              DESCRIPTION as projectName,
              acx.LEGALENTITY as SPV,
              /*
          0 - Distributed Solar  :  Solar
          1 - Open Access Solar : Solar
          2 - Battery : Solar (Can be ignored as of now)
          3 - Wind : Wind
          4 - Hybrid :  Wind*/
              OAPLANT as projectType,
              acx.TENURE as projectLife,
              STARTINGTARIFF as tariff,
              ESCALATION as escalation,
              AVG(fp3.YIELD) averageTemperature,


              AVG(estpr.YIELD)/100 firstYearPR,

              ppp.ESTIMATEDYIELDY1 as estimatedYieldYear1,
              -- Ignoring this two for now, althought according to the mapping this are the ones for year 1
--     AVG(esty.YIELD) estimatedYield,
--     AVG(esty.YIELD) windYieldYear1,

              (ppp.DEGRADATIONY1 + ppp.DEGRADATIONY2)/200 degradation,
              ppp.INCOMETAXRATE/100 as incomeTaxRate,
              ppp.BOOKDEPRECIATIONRATE/100 as depreciationAsCoAct,
              ppp.ITDEPRECIATIONRATE/100 as depreciationAsItAct,
              ppp.ITACCELERATEDDEPRECIATIONRATE/100 as additionalDepreciationRate,
              ppp.DEBTRATIO/100 as loanAmount,
              ppp.INTERESTRATE/100 as interestRate,
              ppp.DEBTTENOR as debtTenor,
              ppp.PROMOTEREQUITY as promoterEquity,
              ppp.PROMOTERDEBT as promoterDebt,
              ppp.INTERESTRATEPROMOTERDEBT as interestRatePromoterDebt,


              AVG(fp2.YIELD) windYieldAC,
              AVG(fp5.YIELD) averageAirDensity,

              acx.PLANTCAPACITYAC as acCapacity,
              acx.PLANTCAPACITYDC as dcCapacity


          FROM AcxContractBillingSaleOfPowerStaging AS acx
                   LEFT JOIN FPELPlantPerformanceParametersStaging AS ppp
                             ON acx.CONTRACTID = ppp.CONTRACTID
                   LEFT JOIN FPELEstimatedYieldCopy3Staging AS fp3
                             ON ppp.RECID1 = fp3.PARENTRECID
                   LEFT JOIN FPELEstimatedPRStaging AS estpr
                             ON ppp.RECID1 = estpr.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldStaging AS esty
                             ON ppp.RECID1 = esty.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldCopy2Staging AS fp2
                             ON ppp.RECID1 = fp2.PARENTRECID
                   LEFT JOIN FPELEstimatedYieldCopy5Staging AS fp5
                             ON ppp.RECID1 = fp5.PARENTRECID
              
          ${projectIds.length > 0 ? `WHERE acx.CONTRACTID IN (${projectIds.map(p => `'${p}'`).join(',')})` : ''}
          
          GROUP BY acx.CONTRACTID, DESCRIPTION, STARTINGTARIFF, ESCALATION, OAPLANT,
                   acx.TENURE, acx.PLANTCAPACITYDC, ppp.DEGRADATIONY1, ppp.DEGRADATIONY2,
                   ppp.INCOMETAXRATE, ppp.BOOKDEPRECIATIONRATE, ppp.ITDEPRECIATIONRATE,
                   ppp.ITACCELERATEDDEPRECIATIONRATE, ppp.DEBTRATIO, ppp.INTERESTRATE,
                   ppp.DEBTTENOR, ppp.PROMOTEREQUITY, ppp.PROMOTERDEBT, ppp.INTERESTRATEPROMOTERDEBT,
                   acx.PLANTCAPACITYAC, acx.LEGALENTITY, ppp.ESTIMATEDYIELDY1, ppp.SOLARGISYIELD
  `;


  const handleSync = () => {
    console.log("Syncing projects", selected)
    setLoading(true)
    syncProjects(companyId, selected).then(() => {
      setLoading(false)
      info("Projects Synced")
    }).catch((e) => {
      setLoading(true)
      error(`Error syncing projects ${e.message}`)
    });
  }

  const handleActualsSync = () => {
    console.log("Syncing projects", selected);
    setLoading(true)
    syncProjects(companyId, selected, 'actuals', {dateFrom: startDate.getTime(), dateTo: endDate.getTime()}).then(() => {
      info("Project Actuals Synced")
      setLoading(false)
    }).catch((e) => {
      error(`Error syncing projects ${e.message}`)
      setLoading(false)
    });
  }

  return (
    <div>
      <div>
        <Header floated="left" as="h3">Projects to Sync</Header>
        <Button primary floated="right" content={"Project Sync"} onClick={handleSync}/>
        <div style={{clear: 'both'}}></div>
        <div>
          <DateRange initialStartDate={startDate}
                     initialEndDate={endDate}
                     onSelect={
                       (start, end) => {
                         setStartDate(start)
                         setEndDate(end)
                       }
                     }
          />
          <Button content={"Actuals Sync"} onClick={handleActualsSync}/>
        </div>
      </div>

      <div style={{clear: 'both'}}></div>

      <ClosableSection opened={false} title={"Data Source Config"} level={"title-bar"}><Segment>
        <strong>Data Source:</strong> SqlServer.FourthPartner <br/>
        <strong>Query:</strong>
        <CodeEditor code={query} language={"sql"} onChanged={() => {
        }}/>
      </Segment>
      </ClosableSection>
      <p></p>

        {loading && <SmallLoading message="loading projects"/>}

        <div style={{width: '100%'}}>
          <DataGridPro
              density={'compact'}
              rows={rows}
              columns={columns}
              pagination={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 30,
                  },
                }
              }}
              autoHeight
              columnBuffer={2} columnThreshold={2}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                }
              }}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(selection) => {
                console.log(selection)
                setSelected(selection as string[])
              }}
          />
        </div>
      </div>);
}