import React, {useEffect, useState} from "react";
import {getAmProjects} from "../projects/amProject.client";
import {useCompanyId} from "../../core";
import {AmProject, AmScenario, FiltersDetails, getProjectVersion, getStoreId} from "../../ps-types";
import {Button, Container, Grid, Radio, Segment, Select, SemanticCOLORS} from "semantic-ui-react";
import {MultiSelect} from "../../ui/MultiSelect";
import {flatten} from "ramda";
import {getScenarios, saveScenario} from "./scenarios.client";
import {CreateEntity} from "../../ui/crud/CreateEntity.component";
import {useInstantUpdater} from "../../generic.hooks";
import {useMessages} from "../../ui/MessagesProvider";
import {compareNormalized} from "../../ps-models";
import {usePopup} from "../../ui/Popup";
import {ContentPopup} from "../../ui/ContentPopup";
import {buildForm} from "../../ui/crud/common/Field";
import {ValueType} from "../../ps-models/line-items";
import { ScenarioContext, buildQueryFromFilters } from "./ScenarioFilter.component";
import { useDashboardService } from "../builder/DashboardConfigService";


export function useScenarioSelect(hideCompareWith: boolean = false, onScenarioChange?: (selectedStores: string[], compareWithStores: string[], persistedUserChoices?: {selected: Omit<ScenarioContext, 'storeIds'>, compareWith: Omit<ScenarioContext, 'storeIds'>}) => void, paramsMap?: AmScenario['paramsMap'], filtersMap?: Record<string, string[]>){
  let [selectedContext, setSelectedContext] = useState<ScenarioContext>({storeIds: [], paramsMap: undefined, filterDetails: undefined})
  let [compareWithContext, setCompareWithContext] = useState<ScenarioContext>({storeIds: [], paramsMap: undefined, filterDetails: undefined})

  useEffect(() => {

    const {storeIds: selectedStoreIds,...persistedUserChoicesFromSelectedContext} = selectedContext;
    const {storeIds: compareWithStoreIds,...persistedUserChoicesFromCompareWithContext} = compareWithContext;

    if(selectedStoreIds.length === 0) return;

    onScenarioChange && onScenarioChange(selectedStoreIds, compareWithStoreIds, {
      selected: persistedUserChoicesFromSelectedContext,
      compareWith: persistedUserChoicesFromCompareWithContext
    });
  }, [selectedContext, compareWithContext]);


  return {
      selectedStores: selectedContext.storeIds,
      component: <ScenarioComponent
          hideCompareWith={hideCompareWith}
        compareWithStores={compareWithContext.storeIds}
        setCompareWithContext={setCompareWithContext}
        selectedStores={selectedContext.storeIds}
        setSelectedContext={setSelectedContext}
        currentParamsMap={paramsMap}
        currentFiltersMap={filtersMap}
      />,
    compareWithStores: compareWithContext.storeIds
  }
}

export function ScenarioComponent({setSelectedContext, setCompareWithContext, hideCompareWith,currentParamsMap, currentFiltersMap}:
                             {
                               hideCompareWith: boolean,
                               compareWithStores?: string[], setCompareWithContext: (context:ScenarioContext) => void,
                               selectedStores: string[], setSelectedContext: (context:ScenarioContext) => void,
                               currentParamsMap?: Record<string, ValueType>,
                               currentFiltersMap?: AmScenario['filtersMap']
                             }) {
  let companyId = useCompanyId();
  let [loading, setLoading] = useState<boolean>(true);
  let [projects, setProjects] = useState<AmProject[]>();
  let [updateId, update] = useInstantUpdater()
  let [scenarios, setScenarios] = useState<AmScenario[]>([]);
  let [selectedScenario, setSelectedScenario] = useState<string>('');
  let {error} = useMessages();
  let [compareWith, setCompareWith] = useState<string>('');
  let [scenarioTitle, setScenarioTitle] = useState<string>('');
  const { getService } = useDashboardService();
  const dbService = getService();
  let compareWithName = '';

  if(compareWith) {
      let compareWithScenario =  scenarios.find(s => s.id === compareWith);
      compareWithName = `Comparing With: ${compareWithScenario?.name}`
  }

  let [selectedContextInternal, setSelectedContextInternal] = useState<ScenarioContext>({storeIds: [], paramsMap: undefined, filterDetails: undefined});

  useEffect(() => {
    if(selectedContextInternal.storeIds.length > 0) {
      setSelectedContext(selectedContextInternal);
    }
  }, [selectedContextInternal]);

  useEffect(() => {
    getAmProjects(companyId).then(projects => {
      setProjects(projects);
      if(!selectedScenario) {
        setSelectedContextInternal({storeIds: projects
              ?.filter(p => p.defaultStoreId).map(p => p.defaultStoreId!) ?? [], paramsMap: undefined, filterDetails: undefined})
        setScenarioTitle('Latest Versions');
        setLoading(false);
      }
    });

  }, [companyId]);

  useEffect(() => {
    getScenarios(companyId).then(scenarios => {
      setScenarios(scenarios);
    });
  }, [companyId, updateId]);

  useEffect(() => {
    if(compareWith) {
      let scenario = scenarios.find(s => s.id === compareWith);
      if(scenario) {
        let compareStoreIds = scenario.selectedProjectVersions.map(s => getStoreId(s.projectId, s.versionId));
        let compareStoreParamsMap = scenario?.paramsMap;
        compareStoreIds = Array.from(new Set(compareStoreIds))
        setCompareWithContext({storeIds: compareStoreIds, paramsMap: compareStoreParamsMap, filterDetails: undefined})
      }
    }
  }, [compareWith]);


  useEffect(() => {
    if(selectedScenario) {
      let scenario = scenarios.find(s => s.id === selectedScenario);
      if(scenario) {
        const filterDetails: FiltersDetails = {
          filtersMap: scenario?.filtersMap as AmScenario["filtersMap"] || {},
          initialFiltersMap: dbService.getGlobalContext()?.filterDetails?.initialFiltersMap,
          filterQuery: buildQueryFromFilters(scenario?.filtersMap),
        };
        setSelectedContextInternal({storeIds: scenario.selectedProjectVersions.map(s => getStoreId(s.projectId, s.versionId)), paramsMap: scenario?.paramsMap, filterDetails})
        setScenarioTitle(scenario.name);
      }
    }
  }, [selectedScenario]);

  let projectOptions = (projects && flatten(projects
    .filter(p => p.versions.length > 0 && p.defaultStoreId)
    .map(p => ([{
      key: p.defaultStoreId!,
      value: p.defaultStoreId!,
      text: p.name,
      isHeader: true
    },
      ...p.versions.sort((a, b) => {
          if (a.versionId === p.defaultVersion) return -1;
          if (b.versionId === p.defaultVersion) return 1;
          return -a.title.localeCompare(b.title);
      }).map(v => ({
        key:  getStoreId(p.id, v.versionId),
        value: getStoreId(p.id, v.versionId),
        text: `${v.title}${p.defaultVersion === v.versionId ? ' (default)' : ''}`
      }))
    ])))) || [];



  function handleSaveScenario(scenario: {name: string}) {
    if(!projectOptions) return;

    //Check scenarios have different names and selection.
    if(scenarios.find(s => compareNormalized(s.name, scenario.name))) {
      error('Scenario with the same name and selection already exists');
    }

    for (let i = 0; i < scenarios.length; i++) {
       let selected = scenarios[i].selectedProjectVersions
         .map(s => getStoreId(s.projectId, s.versionId))
         .sort().join(',');
        let newSelected = selectedContextInternal.storeIds.sort().join(',');

        if(selected === newSelected
          && JSON.stringify(scenarios[i].paramsMap ?? {}) === JSON.stringify(currentParamsMap ?? {})
          && JSON.stringify(scenarios[i].filtersMap ?? {}) === JSON.stringify(currentFiltersMap ?? {})) {
          error(`Scenario ${scenarios[i].name} has the same selection and set of inputs(if provided)`);
          return;
        }

    }

    saveScenario(companyId, {
        name: scenario.name,
        selectedProjectVersions: selectedContextInternal.storeIds.map(s => getProjectVersion(s)),
        paramsMap: currentParamsMap,
        filtersMap: currentFiltersMap
      }).then(res => {
        setSelectedScenario(res.id);
        setScenarioTitle(scenario.name);
        update();
    });
  }

  const handleChangeScenario  = (value: string[]) => {
     setSelectedContextInternal({storeIds: value, filterDetails: currentFiltersMap as FiltersDetails | undefined, paramsMap: currentParamsMap});
     setScenarioTitle('Custom Selection');
  }


  return   <Container style={{paddingBottom: "20px"}} > <Segment>
     <Grid>
        <Grid.Row >
          <Grid.Column width={8}>
            <MultiSelect
              placeholderText={`${scenarioTitle}` }
              hideSelectAll
              hideSelectByGroup
              options={projectOptions}
              initialSelection={projectOptions.filter(o =>  selectedContextInternal.storeIds.includes(o.value as string))}
              onApply={(selectedOptions) =>
                handleChangeScenario(selectedOptions.map(o => o.value as string))}
              disableApplyOnNoSelection={true}
            />
            <LoadScenarios
              scenarios={scenarios}
              setSelectedScenario={setSelectedScenario}
              selectedScenario={selectedScenario}
              updateId={updateId}
            />

          </Grid.Column>
          <Grid.Column floated='right'  width={2}></Grid.Column>
          <Grid.Column floated='right'  width={4}>

            {compareWith && <Button circular icon={"close"}
                                    floated="right"
                                    style={{"margin": "1px"}}
                                    size="tiny" compact
                                    onClick={() => {
                                      setCompareWith('');
                                      setCompareWithContext({storeIds: [], paramsMap: undefined, filterDetails: undefined})
                                    }} />}
            {!hideCompareWith && <LoadScenarios
            updateId={updateId}
            scenarios={scenarios}
            setSelectedScenario={setCompareWith}
            selectedScenario={compareWith}
            text={compareWithName || "Compare With"}
            emptyText={"No Scenarios to Compare With"}
            buttonColor={'orange'}
            styles={{float: "right"}}
          />}


          </Grid.Column>
        </Grid.Row>
     </Grid>

  </Segment>
    </Container>
}
//Component that shows a Popup to capture the Scenario Title and calls saveScenario
export function SaveScenario({onSaving, disable, scenarioTitle}:{disable: boolean,scenarioTitle: string, onSaving: (scenario: {name: string}) => void}) {
  return 
  // <CreateEntity
  //   {...
  //     buildForm(
  //       {
  //         entityName: "Scenario",
  //         initialTitle: "New Scenario",
  //         options: {
  //           buttonText: "Save",
  //           modalTitle: "Save Scenario",
  //           buttonDisabled: disable
  //         },
  //         createEntity: async (formData: any) => {
  //             onSaving(formData);
  //         }
  //       },
  //       {
  //         "name": {
  //           type: "text",
  //           defaultValue: scenarioTitle
  //         }
  //       }
  //     )}
  //   />
}


function LoadScenarios({ selectedScenario,
                         setSelectedScenario,
                         scenarios, text, buttonColor, emptyText, styles
}: {
  updateId: number,
  scenarios: AmScenario[],
  setSelectedScenario: (scenario: string) => void,
  selectedScenario: string
  text?: string,
  emptyText?: string,
  buttonColor?: SemanticCOLORS,
  styles?: any
}) {

  let {openPopup, closePopup} = usePopup();


  let options = scenarios && scenarios.map(s => ({
    key: s.id,
    value: s.id,
    text: s.name
  }));

  let optionsPopup = <ContentPopup
    header={ "Select Scenario"}
    onClose={closePopup}
  ><>
    {options.length === 0 && <div>{emptyText || "No Scenarios"}</div>}
    {options.map(o =>
      <div><Radio
        checked={o.key == selectedScenario}
        label={o.text}

        onClick={(e, data) => {
          setSelectedScenario(o.key as string);
          closePopup();
        }}
      /></div>)}</></ContentPopup>;

  return <>
    <Button
      color={buttonColor}
      size="tiny" onClick={() => openPopup(optionsPopup)}
      style={styles}
    >
      {text || 'Load Scenario'}
    </Button>
  </>
}
