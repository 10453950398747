import React from "react";
import {Button, Container, Grid, Header, Image, Segment} from "semantic-ui-react";
import {REFETCH_ACTION, UpdateProvider, useUpdateContext, WaitForIt} from "../UpdateContext";
import "./AssetsManagement.css"
import demo from "./boards/demo";
import nrn from "./boards/nrn";
import wattSync from "./boards/wattsync";
import "./boards/Ampyr/index"

import {useCompany, useCompanyId} from "../core";
import {clearLineItemsStoreCache} from "../lineitems-store/RestLineItemsStore.persistence";
import {LineItemsStore} from "../ps-models/lineitems-store";
import {
  addStoreFormulas as addDemoStoreFormulas, setDemoLenderGroupings,
  setLabelAndFormatForAggregates as setDemoLabelAndFormatForAggregates
} from "./boards/demo/storeFormulas";
import {setLabelAndFormatForAggregates as setNRNLabelAndFormatForAggregates} from "./boards/nrn/storeFormulas";
import {AMModule} from "./config";
import kelvin from "./boards/Kelvin";
import {AMModuleNames} from "../ps-types";
import {getAmProjectConfig} from "../ps-models";

export const MODULE_DASHBOARD_REGISTRY: Record<AMModuleNames, AMModule> = {
  'NRN': nrn,
  'WATTSYNC': wattSync,
  'DEMO': demo,
  'Kelvin': kelvin,
}

export function AMLogo() {

  let companyId = useCompanyId();

  let title = "";
  let logo = "/am-logo.png";
  if(companyId === "1MUxtCnFnKNJyI03idiO" || companyId === "AOeneWGc2Aim3Ak96nTK") {
    title = "WattSync";
    logo = "/wattsync.jpg";
  }

  return  <>
    <Image circular src={logo} />
    <Header.Content>
      {title}
      <Header.Subheader>Asset Management</Header.Subheader>
    </Header.Content>
  </>
}

export function useAssetManagementModule(): AMModule {
  let company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  let amProjectId = namespace;

  console.info("AM Project Id", `,${amProjectId}.`)

  let amProject = MODULE_DASHBOARD_REGISTRY[amProjectId as AMModuleNames];

  if(!amProject) {
     console.error(`No Asset Management Module found for Project ${amProjectId}`);
     return MODULE_DASHBOARD_REGISTRY['DEMO'];
  }
  return amProject;
}

export function MainBoard() {
  let module = useAssetManagementModule();
  return <UpdateProvider>
      <Header>
        <Container>
      <Grid  verticalAlign='middle'>
        <Grid.Row>
            <Grid.Column width={2}>
              <module.CompanyLogo />
            </Grid.Column>
            <Grid.Column width={11} textAlign="center" >
              <module.MainMenu />
            </Grid.Column>
           <Grid.Column  width={3}>
             <RefreshBtn  />
           </Grid.Column>

        </Grid.Row>
      </Grid>
        </Container>
      </Header>

    <module.MainContent/>

  </UpdateProvider>

}

export function RefreshBtn() {
  let ctx = useUpdateContext();
  return <Button floated="right" onClick={()=> {
    clearLineItemsStoreCache();
    ctx.fireAction(REFETCH_ACTION);
  }} icon="refresh" />
}

export type StoreSetupType = (store: LineItemsStore)=>void
export const MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING: Record<AMModule["Name"], StoreSetupType> = {
// TODO: AM-46 This is a temporary solution, need to invest more time to modify onStoreLoad and ensure nothing breaks
  NRN: (store)=>{
    addDemoStoreFormulas(store);
    setDemoLabelAndFormatForAggregates(store);
    setNRNLabelAndFormatForAggregates(store);
  },
  DEMO: (store)=>{
    addDemoStoreFormulas(store);
    setDemoLabelAndFormatForAggregates(store);
    setDemoLenderGroupings(store);
  },
  WATTSYNC: (store)=>{
    addDemoStoreFormulas(store);
    setDemoLabelAndFormatForAggregates(store);
    // setWSLabelAndFormatForAggregates(store);
  },
  Kelvin:(store)=> {},
};

