import {useCompanyId} from "../../core";
import {useEffect, useState} from "react";
import {AmProject, getStoreId, ProjectVersion} from "../../ps-types";
import {getAmProjects} from "./amProject.client";
import {SmallLoading} from "../../ui/Loading";
import {MultiSelect} from "../../ui/MultiSelect";
import {indexBy, prop} from "ramda";



export interface ProjectVersionSelectionProps {
  title?: string;
  projects?: AmProject[];
  selectedProjectVersions?: ProjectVersion[];
  handleChange: (selectedProjectVersions: ProjectVersion[]) => void;
}




export function useDefaultProjectsVersions() {
  const [projects, setProjects] = useState<AmProject[]>([]);
  const [defaultProjectVersions, setDefaultProjectVersions] = useState<ProjectVersion[]>([]);
  const [status, setStatus] = useState("loading");

  let companyId = useCompanyId();

  useEffect(() => {
    async function update() {
      let projects = await getAmProjects(companyId);
      setProjects(projects);

      let defaultProjectVersions = projects
        ?.filter((p) => p.defaultVersion)
        .map((p) => ({projectId: p.id, versionId: p.defaultVersion! })) ?? []

      setDefaultProjectVersions(defaultProjectVersions);
      setStatus("ready");

    }
    update()
  }, []);

  return {status, projects, defaultProjectVersions};
}

/**
 * @param title
 * @param selectedProjectIds
 * @constructor
 */
export function ProjectVersionSelection({title, projects, selectedProjectVersions, handleChange}: ProjectVersionSelectionProps) {

  if(!title) title = "Select Project Version(s)";

  if (!projects || !selectedProjectVersions) return <SmallLoading/>;

  const projectVersionOptions = buildProjectVersionOptions(projects);

  let projectOptionsByValue = indexBy(prop("value"), projectVersionOptions);

  let selectedProjectOptions = selectedProjectVersions.map((p) => `${p.projectId}-${p.versionId}`)

  return <MultiSelect
    placeholderText={title}
    hideSelectAll
    hideSelectByGroup
    options={projectVersionOptions}
    initialSelection={projectVersionOptions.filter((o) => selectedProjectOptions.includes(o.value))}
    onApply={(selectedOptions) =>
      handleChange(
        selectedOptions.map((o) => {
          return projectOptionsByValue[o.value].projectVersion!;
        })
      )
    }
    disableApplyOnNoSelection={true}
  />
}

function buildProjectVersionOptions(projects: AmProject[]) {
  let projectOptions: {
    key: string;
    value: string;
    text: string,
    isHeader: boolean,
    projectVersion?: ProjectVersion
  }[] = [];

  projects.filter((p) => p.versions.length > 0 && p.defaultStoreId).forEach((p) => {
    //add header
    projectOptions.push({
      key: p.defaultStoreId!,
      value: p.defaultStoreId!,
      text: p.name,
      isHeader: true,
    });

    const sortedVersions = p.versions.sort((a, b) => {
      if (a.versionId === p.defaultVersion) return -1;
      if (b.versionId === p.defaultVersion) return 1;
      return -a.title.localeCompare(b.title);
    });

    //add versions
    sortedVersions.forEach((v) => {
      projectOptions.push({
        key: `${p.id}-${v.versionId}`,
        value: `${p.id}-${v.versionId}`,
        text: `${v.title}${p.defaultVersion === v.versionId ? " (default)" : ""}`,
        isHeader: false,
        projectVersion: {projectId: p.id, versionId: v.versionId}
      });
    });

  });

  return projectOptions;
}