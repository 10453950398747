import {z} from "zod";
import {AmVersion} from "./AssetManagement";
import {ValueType} from "../ps-models/line-items";

export interface AmProject {
  companyId: string,
  id: string,
  name: string,
  defaultVersion?: string,
  defaultVersionData?: AmVersion['versionData'],
  versions: AmVersion[],
  fileProcessedAt?: number
  createdAt: number,
  defaultStoreId?: string
}

export function getDefaultProjectVersion(project: AmProject) {
    if (!project.defaultVersion) throw new Error("Default version is not set");
    let version = project.versions.find(v => v.versionId === project.defaultVersion)!

    return {
        projectId: project.id,
        versionId: version.versionId
    }
}

export function getProjectVersion(storeId: string): ProjectVersion {
    const parts = storeId.split(':');
    return {
        projectId: parts[1],
        versionId: parts[2]
    }
}

export interface ProjectVersion {
    projectId: string,
    versionId: string
}

export function getStoreId(projectId: string, versionId: string) {
    return `p:${projectId}:${versionId}`;
}


export interface ProjectAndVersionCloningIdMapping {
    source: { projectId: string, versionId: string },
    target: { projectId: string, versionId: string }
}


export const createBulkProjectSchema = z.object({
    versionTitle: z.string(),
    versionDescription: z.string().nonempty("Version Description is required"),
    companyId: z.string().nonempty("CompanyId is required"),
});


export type ProjectToSync = {
  projectId: string,
  projectName: string,
  [key: string]: ValueType
}