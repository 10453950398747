import React, {useMemo, useState} from 'react';
import {MultiSelect, MultiSelectOption} from "../../ui/MultiSelect";
import {Button, Dimmer, Grid, Header, Input, Loader, Modal, Segment} from "semantic-ui-react";
import {Site, useAllSites} from "../siteHooks";
import {normalizeString, getAmProjectConfig} from "../../ps-models";
import {LineItemBatch, queryLineItemOnSites} from "./lineItemBatchOperations";
import {BatchEditor} from "./BatchEditor";
import {usePopup} from "../../ui/Popup";
import {Loading} from '../../ui/Loading';
import {authStorage} from "../../auth";

export function LineItemsBatchUpdater(): JSX.Element {
  const sites = useAllSites();
  if(sites === undefined) {
    return <Loading />
  }

  return <LineItemsBatchUpdaterFrontend sites={sites} />
}

function LineItemsBatchUpdaterFrontend({sites}: {sites: Site[]}) {
  const siteOptions = useMemo(() => sites.map(({id, label}) => ({
    key: id,
    value: id,
    text: label
  })), [sites]);

  const company = authStorage.getCompany();
  const { collection } = getAmProjectConfig(company);
  const [selectedSiteOptions, setSelectedSiteOptions] = useState<MultiSelectOption[]>(siteOptions);
  const [searchValue, setSearchValue] = useState('');
  const [lineItemBatches, setLineItemBatches] = useState<LineItemBatch[]>([]);
  const { closePopup, openPopup } = usePopup()

  async function addLineItem() {
    const lineItemName = normalizeString(searchValue)
    if (lineItemBatches.find(({lineItemName: liName}) => liName === lineItemName)) {
      openPopup(<AlreadyExistsPopup lineItemName={searchValue} onClose={closePopup} />);
      return
    } else if (lineItemName === '') {
      openPopup(<EmptyLineItemNamePopup onClose={closePopup} />);
      return
    }

    const [store, queryResult, missingLineItems, existingLineItems] = await queryLineItemOnSites(
        collection,
        lineItemName,
        selectedSiteOptions.map(opt => ({id: opt.value as string, label: opt.text}))
    )

    function doAddLineItem() {
      setLineItemBatches([...lineItemBatches, {lineItemName, siteLineItems: existingLineItems, queryResult, store}])
    }

    if (existingLineItems.length === 0) {
      openPopup(<NoLineItemsPopup lineItemName={searchValue} onClose={closePopup} />);
    } else if (missingLineItems.length > 0) {
      openPopup(<MissingLineItemsPopup lineItemName={searchValue} onClose={closePopup} missingLineItems={missingLineItems} existingLineItems={existingLineItems} onAdd={doAddLineItem} />);
    } else {
      doAddLineItem()
    }
  }

  function removeLineItem(lineItemName: string) {
    setLineItemBatches(lineItemBatches.filter(({lineItemName: liName}) => liName !== lineItemName))
  }

  return <div>
    <Header>Line Items Batch Updater</Header>
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <MultiSelect
              options={siteOptions}
              onChanged={setSelectedSiteOptions}
              placeholderText={multiSitesSelectText(selectedSiteOptions.length, siteOptions.length)}
              initialSelection={siteOptions}
              hideSelectByGroup
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Input
              action={{
                color: 'blue',
                labelPosition: 'right',
                icon: 'add',
                content: 'Add',
                onClick: addLineItem
              }}
              placeholder="Line Item name..."
              value={searchValue}
              disabled={selectedSiteOptions.length === 0}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {lineItemBatches.length > 0 && <>
        <Header>Line Items batches to update:</Header>
        {lineItemBatches.map((props) => <BatchEditor
            {...props}
            onClose={() => removeLineItem(props.lineItemName)}
        />)}
      </>}
    </Segment>
  </div>
}

function MissingLineItemsPopup({lineItemName, missingLineItems, existingLineItems, onAdd, onClose}: {lineItemName: string, missingLineItems: Site[], existingLineItems: Site[], onAdd: () => void, onClose: () => void}) {
  return <Modal onClose={onClose} size="tiny" open={true}>
      <Modal.Header>Missing Line Item {lineItemName} in some Sites</Modal.Header>
      <Modal.Content>
        {missingLineItems.map(item => <p key={item.id}>{item.label} ({item.id})</p>)}
      </Modal.Content>
      <Modal.Actions>
        <Button color="yellow" onClick={() => {
          onClose()
          onAdd()
        }}>
          Edit for {existingLineItems.length} existing sites
        </Button>
        <Button color="green" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
}

function AlreadyExistsPopup({lineItemName, onClose}: {lineItemName: string, onClose: () => void}) {
  return <Modal onClose={onClose} size="tiny" open={true}>
      <Modal.Header>Line Item {lineItemName} already imported in the Editor</Modal.Header>
      <Modal.Content>
        If you wanted to edit a different line item, please change the name and try again.
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
}

function NoLineItemsPopup({lineItemName, onClose}: {lineItemName: string, onClose: () => void}) {
  return <Modal onClose={onClose} size="tiny" open={true}>
      <Modal.Header>No Line Item {lineItemName} in Sites</Modal.Header>
      <Modal.Content>
        We queried all the sites and got no results. Please check the line item name and try again.
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
}

function EmptyLineItemNamePopup({onClose}: {onClose: () => void}) {
    return <Modal onClose={onClose} size="tiny" open={true}>
      <Modal.Header>Empty field</Modal.Header>
      <Modal.Content>Please enter a line item name.</Modal.Content>
      <Modal.Actions><Button color="green" onClick={onClose}>Ok</Button></Modal.Actions>
    </Modal>
}



function multiSitesSelectText(selectedValuesLength: number, sitesLength: number): string {
    let quantity: string
    if (selectedValuesLength === 0) {
        quantity = 'no'
    } else if (selectedValuesLength === sitesLength) {
        quantity = 'all ' + sitesLength
    } else {
        quantity = selectedValuesLength.toString()
    }
    return `${quantity} sites selected`
}