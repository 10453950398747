import {buildWidgetConfig, WidgetRegistryEntry} from "./WidgetRegistry";
import {useDashboardService} from "./DashboardConfigService";
import {Button, Icon, Popup, SemanticICONS} from "semantic-ui-react";
import React from "react";
import {useDrag} from "react-dnd";

export interface DropResult {
  widgetId: string
  [key: string]: any
}
export function DragableAddWidget({widget}: {
  widget: WidgetRegistryEntry
  onDragEnd?: (result: DropResult, widgetType: string) => void
}) {
  let {getService, ready} = useDashboardService();
  let dashboardService = getService();
  const [{isDragging}, drag] = useDrag(() => ({
    type: 'box',
    item: {widgetType: widget.typeId},
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{widgetId: string}>()
      if (item && dropResult && dropResult.widgetId && item.widgetType) {
        dashboardService.getConfig().addWidget(
          buildWidgetConfig('$auto',
            item.widgetType, {
             containerData: dropResult
            }, dropResult.widgetId)
        );

      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  return <div ref={drag} style={{marginBottom: "8px", textAlign: "center"}}
  >
    <Popup
        trigger={<Button  icon ><Icon name={widget.metadata.icon as SemanticICONS} /></Button>}
        content={widget.metadata.name}
    />
  </div>
}