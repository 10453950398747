import {LineItemsStore, StoreQuery} from "../../../ps-models/lineitems-store";
import {LoadingBlock} from "../../../ui/Loading";
import {Button, Checkbox, Input, Segment, SegmentInline, Select} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {TimeSeriesChartOptionsV2, TimeSeriesChartV2} from "../../../lineitems-store/TimeSeriesChartV2";
import {QueryEditor} from "../StoreQueryEditor";
import {TimeIndexEditorStandalone} from "../../line-items-editor/TimeIndexEditor";
import {StoreQueryFlat, StoreQueryFlatDto} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {
  getConfig, getGlobalContext,
  getStore,
  useOnConfigChange,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
} from "./commons";
import {SideBySideView} from "../../SideBySideView";
import {DateRangeType} from "../../../ps-types";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {IChangeEvent} from "@rjsf/core";
import Form from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";

interface ConfigProps {
  title: string,
  chartType: string,
  storeQueryFlat?: StoreQueryFlatDto,
  lineItemSeriesDefinitionOverrides?: TimeSeriesChartOptionsV2['lineItemSeriesDefinitionOverrides']
  plotAtLastDatesOfPeriod?: TimeSeriesChartOptionsV2['plotAtLastDatesOfPeriod']
}

// @TODO: Make this cleaner, better to have some other type extending ConfigProps and over-riding storeQueryFlat
interface DeSerializedConfigProps {
  title: string,
  chartType: string,
  storeQueryFlat?: StoreQueryFlat,
  lineItemSeriesDefinitionOverrides?: TimeSeriesChartOptionsV2['lineItemSeriesDefinitionOverrides'],
  plotAtLastDatesOfPeriod?: TimeSeriesChartOptionsV2['plotAtLastDatesOfPeriod']
}

const DEFAULT_STORE_QUERY_FLAT = new StoreQueryFlat();
registerWidgetType({
    typeId: 'TimeSeries Chart V2',
    metadata: {
      name: 'Bar or Line Chart Alpha',
      description: '',
      icon: 'chart bar',
    },
    defaultConfig: {
      chartType: 'bar',
      storeQueryFlat: DEFAULT_STORE_QUERY_FLAT.serialize(),
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: any) => {
        return <WidgetCompare context={context}/>
    }
  }
)

const deserializeConfig = (config: ConfigProps):DeSerializedConfigProps  =>{
  return {...config, ...(config.storeQueryFlat ? {storeQueryFlat: StoreQueryFlat.deserialize(config?.storeQueryFlat)} :{})} as DeSerializedConfigProps
}

function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {
  let store = context.store as LineItemsStore;
  let [localConfig, setLocalConfig] = React.useState<DeSerializedConfigProps>(deserializeConfig(config));

  let [customizeWidgetTimeIndex, setCustomizeWidgetTimeIndex] = useState<boolean>(!!localConfig.storeQueryFlat?.timeIndex);
  // let [customizeTableRowDimension, setCustomizeTableRowDimension] = useState<boolean>(!!localConfig.table);

  useEffect(() => {
    setLocalConfig(deserializeConfig(config));
  }, [config]);

  if (!store) {
    return <LoadingBlock/>
  }
  const handleApply = () => {
    const serializedConfig = {...localConfig, ...(localConfig.storeQueryFlat ? {storeQueryFlat: localConfig.storeQueryFlat.serialize()} : {})}
    onConfigChange(serializedConfig);
  }

  const handleDiscard = () => {
    setLocalConfig(deserializeConfig(config));
  }

  return <>
    Type:
    <Select
      value={localConfig.chartType}
      onChange={(e, data) => {
        setLocalConfig({...localConfig, chartType: data.value as string})
      }}
      options={[
        {key: 'bar', text: 'Bar', value: 'bar'},
        {key: 'line', text: 'Line', value: 'line'},
      ]}
    />
    <Segment>
      <SegmentInline>
        Title: <Input value={localConfig.title} onChange={(e, data) => {
          setLocalConfig({...localConfig, title: data.value})
        }} />
        <div>
          <Checkbox toggle checked={localConfig?.plotAtLastDatesOfPeriod}
                    onChange={(e, data) => {
                      setLocalConfig({...localConfig, plotAtLastDatesOfPeriod: !!data.checked})
                    }}
                    label={"Plot values at last date of period"} />
        </div>
        <QueryEditor store={store} capabilities={["names"]} query={localConfig.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT} onQueryUpdate={(updatedQuery)=>{
          let query = updatedQuery;
          if(localConfig.storeQueryFlat && localConfig.storeQueryFlat.timeIndex){
            query = updatedQuery.withTimeIndex(localConfig.storeQueryFlat.timeIndex)
          }
          setLocalConfig({...localConfig, storeQueryFlat: query })
        }} />
        <Checkbox toggle checked={customizeWidgetTimeIndex}
                  onChange={(e, data) => {
                    if(!!data.checked === false){
                      setLocalConfig((prev) => {
                        const serializedExistingStoreQueryFlat = (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT)?.serialize();
                        if(serializedExistingStoreQueryFlat){
                          const {timeIndex, ...storeQueryFlatSerializedWithStrippedTimeIndex} = serializedExistingStoreQueryFlat;
                          let storeQueryFlatToSet = StoreQueryFlat.deserialize(storeQueryFlatSerializedWithStrippedTimeIndex)
                          return {...prev, storeQueryFlat: storeQueryFlatToSet }
                        }
                        return {...prev};
                      })
                    }
                    setCustomizeWidgetTimeIndex(!!data.checked)
                  }}
                  label={"Customize Widget Time Index"} />
        {customizeWidgetTimeIndex && <TimeIndexEditorStandalone initiallySelectedDates={{startDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.startDate : store.timeIndex.startDate, endDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.endDate : store.timeIndex.endDate}}
                                                                initialGranularity={localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.getUnit() : store.timeIndex.getUnit()}
                                                                onUpdate={(updatedTimeIndex)=>{
                                                                  if(JSON.stringify(store.timeIndex.serialize()) !== JSON.stringify(updatedTimeIndex.serialize())){
                                                                    setLocalConfig((prev)=>(
                                                                        {...prev, storeQueryFlat: (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT).withTimeIndex(updatedTimeIndex) }))
                                                                  }
                                                                }}
                                                                layout={"vertical"}
        />}
        <Segment>
          Override Item Charting Config
          <ItemChartingOverridesEditor definedOverrides={localConfig?.lineItemSeriesDefinitionOverrides} onSave={(data)=> setLocalConfig({...localConfig, lineItemSeriesDefinitionOverrides: data ?? []})
          } />
        </Segment>
      </SegmentInline>
    </Segment>
    <Button primary onClick={handleApply}>Apply</Button>
    <Button color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>

}

function WidgetCompare({context}: { context: BuilderContext }) {
  useOnConfigChange<ConfigProps>(context);
  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);

  const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
  const mainStore = context.appContext.getStore();
  if(storeToCompareWith){
    return <SideBySideView selectedStores={mainStore}
                           compareWithStores={storeToCompareWith}
                           highlightComparedWithStore={true}
                           render={(store) =>
                             <Widget context={{...context, store}} />}
    />
  }
  return <Widget context={{...context, store: mainStore}}/>
}

function Widget({context}: { context: BuilderContext }) {

  let store = getStore(context);
  let config = getConfig(context);
  let globalContext = getGlobalContext(context)

  let baseQueryForGettingGroupingFields = StoreQuery.withField('store_groupingName');
  let query = baseQueryForGettingGroupingFields;
  let timeIndex = store.timeIndex;
  if(config.storeQueryFlat && JSON.stringify(config.storeQueryFlat) !== JSON.stringify(DEFAULT_STORE_QUERY_FLAT.serialize())){
    let deserializedSQF = StoreQueryFlat.deserialize(config.storeQueryFlat);
    query = StoreQueryFlat.deserialize(config.storeQueryFlat).toStoreQuery(false).and(baseQueryForGettingGroupingFields);
    if(deserializedSQF.timeIndex){
      timeIndex = deserializedSQF.timeIndex;
    }
  }

  if(globalContext.utcDateRange) {
    const dateRange: DateRangeType = globalContext.utcDateRange;
    const from = dateRange.from || timeIndex.startDate;
    const to = dateRange.to || timeIndex.endDate;
    timeIndex = timeIndex.withDates(from, to);
    query = query.withTimeIndex(timeIndex);
  }

  if(globalContext.granularity) {
    timeIndex = timeIndex.withGranularity(globalContext.granularity);
    query = query.withTimeIndex(timeIndex);
  }
  let results = store.query(query, {sortedNames: (Array.isArray(config.storeQueryFlat?.lineItems) && config.storeQueryFlat?.lineItems.length >0) ? config.storeQueryFlat?.lineItems : context.query.lineItems});

  return <>
    <TimeSeriesChartV2 title={config.title}
                     options={{type: config.chartType,
                       granularity: timeIndex.getUnit(),
                     ...(config?.plotAtLastDatesOfPeriod !==undefined ? {plotAtLastDatesOfPeriod: config.plotAtLastDatesOfPeriod}: {}),
                     ...((Array.isArray(config?.lineItemSeriesDefinitionOverrides) && config.lineItemSeriesDefinitionOverrides.length>0) ? {lineItemSeriesDefinitionOverrides: config.lineItemSeriesDefinitionOverrides }: {})
                     }}
                     result={results}
   />
  </>
}

const itemChartingOverridesSchema: RJSFSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: "Item Name"
      },
      config: {
        type: 'string',
        title: "JSON Configuration",
        default: `{"name": "Item 1", "type": "line", "smooth": true}`
      },
    },
    required: ["name", "config"]
  },
};

const itemChartingOverridesUiSchema: UiSchema = {
  "ui:options": {
    orderable: false,
    addable: true,
    removable: true,
  },
  "ui:submitButtonOptions": { submitText: "Finalize Overrides" },
};

// @TODO: Use json-schema-generator or ts-json-schema-generator to create the json schema directly using the type TimeSeriesChartOptionsV2['lineItemSeriesDefinitionOverrides'].
type ItemChartingOverridesFormData = TimeSeriesChartOptionsV2['lineItemSeriesDefinitionOverrides'] | null;
function ItemChartingOverridesEditor({definedOverrides, onSave}: {definedOverrides?: TimeSeriesChartOptionsV2['lineItemSeriesDefinitionOverrides'], onSave: (itemChartingOverrides: ItemChartingOverridesFormData)=>void}){
  const [formData, setFormData] = useState<ItemChartingOverridesFormData>(null);
  useEffect(()=>{
    setFormData(()=>{
      // @TODO: Once we use json-schema-generator, we will also remove all this stringification logic too.
      return definedOverrides?.map((c)=>({...c, config: JSON.stringify(c.config)})) ?? null;
    })
  }, [definedOverrides])

  const handleSubmit = (data: IChangeEvent<ItemChartingOverridesFormData>) => {
    let hasErrors = true;
    if(data.formData && Array.isArray(data.formData) &&  data.formData.length>0){
      hasErrors = false;
      for (const item of data.formData) {
        if (!item.name || item.config === undefined) {
            hasErrors = true;
        } else {
          try {
            item.config = JSON.parse(item.config as string);
          } catch(err){
            hasErrors = true;
            console.error("Error parsing JSON", err);
          }
        }
      }
    }
    if(!hasErrors){
      onSave(data.formData ?? null);
    }
  };

  return <Form schema={itemChartingOverridesSchema} uiSchema={itemChartingOverridesUiSchema} validator={validator}
               formData={formData}
               // onChange={onFormChange}
               onSubmit={handleSubmit}
      // customValidate={validate}
  />
}