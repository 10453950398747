import React, {useEffect, useMemo, useState} from 'react';
import {Button, Dropdown, Icon, Segment, Select, Table} from "semantic-ui-react";
import {keys} from "ramda";
import {LineItemsStore, PersistenceQuery, pQuery} from "../../../ps-models/lineitems-store";
import {LineItemFieldFilter} from "../../../ps-types";
import {ValueType} from "../../../ps-models/line-items";
import {usePSQuery} from "../../../lineitems-store/LineItemsStore.hook";
import {LoadingBlock} from "../../../ui/Loading";
import {StoreQueryFlat} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

interface Props {
    store: LineItemsStore;
    selectedFields: LineItemFieldFilter[];
    onSelected: (selections: LineItemFieldFilter[])=>void,
    onUpdate?: (updatedData: LineItemFieldFilter[], deleted: string[]) => void;
}

export function QueryFieldFilterEditor<T extends PersistenceQuery | StoreQueryFlat>({query, store, onQueryUpdate}: {query: T,store?: LineItemsStore, onQueryUpdate: (updatedQuery: T) =>void}) {
    const [localQuery, setLocalQuery] = useState<T>(query);
    let [selectedFieldFilters, setSelectedFieldFilters] = useState<LineItemFieldFilter[]>(query.serialize()?._selectLineItemsHavingFields ?? []);

    useEffect(() => {
        setLocalQuery(query);
        setSelectedFieldFilters(query.serialize()?._selectLineItemsHavingFields ?? []);
    }, []);


    useEffect(()=> {
        handleFieldFiltersChange();
    }, [selectedFieldFilters])

    const handleFieldFiltersChange = () => {
        localQuery.selectLineItemsHavingFields(selectedFieldFilters);
        setLocalQuery(localQuery);
        onQueryUpdate(localQuery);
    }

    const handleSelectedFieldFilters = (lineItemFieldFitlers: LineItemFieldFilter[]) => {
        setSelectedFieldFilters(lineItemFieldFitlers);
    }

    return <>
        {store ? <FieldsSelector store={store} selectedFields={selectedFieldFilters} onSelected={handleSelectedFieldFilters} /> : <FieldsSelectorForAggregatedStore selectedFields={selectedFieldFilters} onSelected={handleSelectedFieldFilters} />}
    </>
}

const buildSelectOption = (value: ValueType)=> {
    return {
        value: value,
        text: value,
        key: value.toString()
    }
}

export const FieldsSelector: React.FC<Props> = ({ store, selectedFields, onSelected, onUpdate }) => {
    let [selectedFieldFilters, setSelectedFieldFilters] = useState<LineItemFieldFilter[]>(selectedFields);
    let completeFieldIndex = store.getDataSet().getFieldsIndex();
    let allFieldNames = keys(completeFieldIndex);
    let selectedFieldNames = selectedFieldFilters.map((f)=>f.name);

    let selectableFieldNameOptions = allFieldNames.filter((fieldName)=>!selectedFieldNames.includes(fieldName)).map(fieldName => (buildSelectOption(fieldName)));

    const allowFieldAddition = useMemo(()=>selectableFieldNameOptions.length>0, [selectableFieldNameOptions])

    useEffect(()=>{
        onSelected(selectedFieldFilters);
    }, [selectedFieldFilters])

    const handleUpdate = (key: number, field: keyof LineItemFieldFilter, value: string | LineItemFieldFilter['value']) => {
        const updatedData = selectedFieldFilters.map((item, index) => {
            if (index === key) {
                if(field === "name"){ // reset selected values
                    return {...item, [field]: value as string, value: null}
                }
                if(field === "value"){
                    return { ...item, [field]: value as ValueType[] };
                }
            }
            return item;
        });
        setSelectedFieldFilters(updatedData)
    };

    const handleAdd = () => {
        setSelectedFieldFilters([...selectedFieldFilters, {name: selectableFieldNameOptions[0]?.value.toString() ?? '', value: null,
        }])
    };

    const handleDelete = (keyToDelete: number) => {
        setSelectedFieldFilters(selectedFieldFilters.filter((_, key) => key !== keyToDelete))
    };

    return (
        <Segment>
            <h5>Select With Fields</h5>
            <Table basic='very' celled collapsing style={{width: "100%"}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Specific Value (if any)</Table.HeaderCell>
                        {/*<Table.HeaderCell>Selection Criteria</Table.HeaderCell>*/}
                        <Table.HeaderCell>
                            <Button icon size="mini" onClick={handleAdd} disabled={!allowFieldAddition}>
                                <Icon name='plus' />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {selectedFieldFilters.map((fieldData, key) => (
                        <Table.Row key={key}>
                            <Table.Cell>
                                <Select
                                    style={{width: "100%"}}
                                    search selection
                                    options={selectableFieldNameOptions}
                                    placeholder={fieldData.name}
                                    value={fieldData.name}
                                    onChange={ (e, data) =>
                                        handleUpdate(key,"name", data.value as string) } />
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                    style={{width: "100%"}}
                                    search
                                    selection
                                    options={completeFieldIndex[fieldData.name]?.map((val)=>buildSelectOption(val)) ?? []}
                                    placeholder={'No value selected'}
                                    value={fieldData.value ?? []}
                                    clearable
                                    multiple
                                    onChange={ (e, data) =>{
                                        let val = data.value;
                                        if(Array.isArray(val) && val.length === 0){
                                            val = undefined;
                                        }
                                        if(val === undefined){
                                            val = undefined;
                                        }
                                        handleUpdate(key,"value", val as LineItemFieldFilter['value'])
                                    }}
                                />
                        </Table.Cell>
                            <Table.Cell>
                                <Button size="mini" icon  onClick={() => handleDelete(key)}>
                                    <Icon  name='trash' />
                                </Button>
                            </Table.Cell>
                        </Table.Row>

                    ))}
                </Table.Body>
            </Table>

        </Segment>
    );
};

function FieldsSelectorForAggregatedStore({ onSelected, selectedFields}:{onSelected: (fieldFilter: LineItemFieldFilter[]) => void, selectedFields: LineItemFieldFilter[]}){
    const company = authStorage.getCompany();
    let { collection } = getAmProjectConfig(company);
    let store = usePSQuery(collection, pQuery().lineItemsMetadata());

    if(!store) return <LoadingBlock />;
    return <FieldsSelector store={store} onSelected={onSelected} selectedFields={selectedFields} />
}
