import psPlatformClient from "../../psPlatformClient";
import {
  CreateWhatIfScenarioRequest,
  ListWhatIfScenariosResponse,
  UpdateWhatIfScenarioRequest, WhatIfScenarioValuesPerProject,
  WhatIsScenarioRecord
} from "../../ps-types";


//Client for whatIfScenario.router.ts
export const createWhatIfScenario = async (companyId: string, data: CreateWhatIfScenarioRequest) => {
  const result = await psPlatformClient<CreateWhatIfScenarioRequest, WhatIsScenarioRecord>(
    {method: 'post', url: `/am/${companyId}/what-if-scenario`,
    data })
  return result.data;
}

export const updateWhatIfScenario = async (companyId: string, scenarioId: string, data: UpdateWhatIfScenarioRequest) => {
  const result = await psPlatformClient<any, any>(
    {method: 'put', url: `/am/${companyId}/what-if-scenario/${scenarioId}`,
    data })
  return result.data;
}

export const deleteWhatIfScenario = async (companyId: string, scenarioId: string) => {
  const result = await psPlatformClient<any, any>(
    {method: 'delete', url: `/am/${companyId}/what-if-scenario/${scenarioId}`})
  return result.data;
}

export const listWhatIfScenarios = async (companyId: string) => {
  const result = await psPlatformClient<any, ListWhatIfScenariosResponse>(
    {method: 'get', url: `/am/${companyId}/what-if-scenario/` })
  return result.data;
}

export const getWhatIfScenario = async (companyId: string, scenarioId: string): Promise<WhatIsScenarioRecord> => {
  const result = await psPlatformClient<{companyId: string, scenarioId: string }, any>(
    {method: 'get', url: `/am/${companyId}/what-if-scenario/${scenarioId}` })
  return result.data;
}

export const runWhatIfScenario = async (companyId: string, scenarioId: string) => {
  await psPlatformClient<{companyId: string, scenarioId: string }, any>(
    {method: 'post', url: `/am/${companyId}/what-if-scenario/${scenarioId}/run` })
}

export const getSalesforceValues = async (companyId: string, configKey: string): Promise<WhatIfScenarioValuesPerProject> => {
  const result = await psPlatformClient<null, WhatIfScenarioValuesPerProject>(
    {method: 'get', url: `/am/${companyId}/integrations/salesforce/${configKey}/values` })
  return result.data;
}

//get fields
export const getSalesforceFields = async (companyId: string, configKey: string): Promise<string[]> => {
  const result = await psPlatformClient<null, string[]>(
    {method: 'get', url: `/am/${companyId}/integrations/salesforce/${configKey}/fields` })
  return result.data;
}



