import React, {Profiler, useEffect, useMemo} from "react";
import {getAmDashboard} from "./Dashboard.client";
import {useCompanyId, useCurrentUser, usePlatformUrls} from "../../core";
import {UpdateProvider, WaitForIt, WaitForItV2} from "../../UpdateContext";
import {
  buildConfigService,
  buildConfigServiceFromDashboard,
  DashboardConfigService,
} from "./DashboardConfigService";
import {WidgetWrapper} from "./WidgetWrapper";
import {DashboardPageStoreCtxLoader} from "./DashboardPageStoreCtxLoader";
import {AmDashboard, DateRangeType} from "../../ps-types";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

export function DashboardBoardView({dashboardId, onServiceReady, widgetParams, dashboardData, constrainedViewDateRange}:{
  dashboardId: string,
  dashboardData?: AmDashboard,
  widgetParams?: Record<string, any>,
  onServiceReady?: (service: DashboardConfigService) => void,
  constrainedViewDateRange?: DateRangeType
}) {
  let companyId = useCompanyId();
  let isAdmin = useCurrentUser().isAdmin();
  let {amDashboardEditor} = usePlatformUrls()

  return <UpdateProvider loadService={async ()=> {
    let service;
    if(dashboardData) {
      service = await buildConfigService(dashboardData, true, constrainedViewDateRange);
    } else {
      service = await buildConfigServiceFromDashboard(companyId, dashboardId, true, constrainedViewDateRange);
    }
     onServiceReady?.(service);
    
     return service;
  }} >
    <WaitForItV2>

      {isAdmin && <Link
        style={{position: 'absolute', right: '10px', top: '-35px'}}
        to={amDashboardEditor(dashboardId)}><Button>Edit</Button></Link>}

        <DashboardPageStoreCtxLoader
            render={( service)=>
              <WidgetWrapper widgetId={'root'} context={{
                appContext: service,
                widgetParams: widgetParams
              }} />}
        />
    </WaitForItV2>
  </UpdateProvider>
}