import {ProjectToSync} from "../../ps-types";
import psPlatformClient from "../../psPlatformClient";


export const getProjectsToSync = async (companyId: string): Promise<ProjectToSync[]> => {
  const result = await psPlatformClient<{companyId: string}, ProjectToSync[]>(
    {method: 'get', url: `/am/${companyId}/project/sync`, data: {companyId}})
  return result.data;
}

//POST  `/am/${companyId}/project/sync' body: projectIds
export const syncProjects = async (companyId: string, projectIds: string[], action: string = "", data: Record<string, any> = {}) => {
  const result = await psPlatformClient<{companyId: string, projectIds: string[]}, null>(
    {method: 'post', url: `/am/${companyId}/project/sync/${action}`, data: {companyId, projectIds, ...data}})
  return result.data;
}