import {LineItemsStore} from "../../ps-models/lineitems-store";

interface ModuleDefinition {
    onStoreInit: (store: LineItemsStore, dashboardId: string) => void;
}

const DashboardConfigServiceRegistry: {
    [key: string]: ModuleDefinition
} = {}


export function registerModule(moduleName: string, def: ModuleDefinition) {
    DashboardConfigServiceRegistry[moduleName] = def;
}

export function runOnDashboardStoreInit(store: LineItemsStore, dashboardId: string) {
    for (let key in DashboardConfigServiceRegistry) {
        DashboardConfigServiceRegistry[key].onStoreInit(store, dashboardId);
    }
}