import {Route} from "react-router-dom";
import React from "react";
import {NewAuthWrapper} from "../auth";
import {PlatformLayout} from "../PlatformLayout";
import { PopupProvider } from "../ui/Popup";
import {AdminPage} from "./admin.page";

function AdminLayout({children, contentWide}: {children: React.ReactNode, contentWide?: boolean}) {
  return <PlatformLayout  topId={"admin-panel"} header={<>Admin Panel</>}
                          contentWide={contentWide}>
      <PopupProvider>
        <div className="AssetsManagement">
          {children}
        </div>
      </PopupProvider>
    </PlatformLayout>
}

const getRoutes =  () => {

  return [
    <Route exact path='/ps/admin'
           render={ () => <NewAuthWrapper>
             <AdminLayout contentWide><AdminPage /></AdminLayout></NewAuthWrapper> } />,
  ]
}

export default getRoutes;