
export const SOURCE_NAME_FIELD = 'store_sourceName'
export const SOURCE_ID_FIELD = 'store_sourceId'
export const SOURCE_LINE_ITEM_NAME = 'store_sourceLineItemName'
export const DEFAULT_SOURCE_GRID = {rowDimension: SOURCE_NAME_FIELD, columnDimension: SOURCE_LINE_ITEM_NAME, rowDimensionLabel: "Site"}

export const NUMBER_OF_STORES_LINE_ITEM = 'store_numberOfStores'

//A private field belongs to a template and is not shared with stores that import it
export const INTERNAL_FIELD = 'store_internal'

export const CANONICAL_NAME_FIELD = 'cname'