import {AmReport, AmReportAndVersionsView, AmReportVersion, ReportTemplateConfig} from "../../ps-types";
import psPlatformClient from "../../psPlatformClient";
import {buildUTCDate, ExcelReportInjectionConfig, OutputMapping} from "../../ps-models";

export const createAmReport = async (companyId: string, name: string, dashboardId: string, config: AmReport['config']) => {
  const result = await psPlatformClient<any, AmReport>(
    {method: 'post', url: `/am/${companyId}/report`,
      data: {companyId,
        dashboardId,
        name,
        config
      } })

  return {
    ...result.data,
    config: deserializeAmReportConfig(result.data.config)
  }
}

export const getAmReports = async (companyId: string): Promise<AmReport[]> => {
  const result = await psPlatformClient<{companyId: string}, AmReport[]>(
    {method: 'get', url: `/am/${companyId}/reports`, data: {companyId}})
  return result.data.map((it)=> ({
        ...it,
        config: deserializeAmReportConfig(it.config)
  }));
}

export const getAmReport = async (companyId: string, reportId: string): Promise<AmReportAndVersionsView> => {
  const result = await psPlatformClient<null, AmReportAndVersionsView>(
    {method: 'get', url: `/am/${companyId}/report/${reportId}`})
  return {
    ...result.data,
    config: deserializeAmReportConfig(result.data.config)
  } ;
}

export const getAmReportVersion = async (companyId: string, reportId: string, versionId: string) => {
  const result = await psPlatformClient<null, AmReportVersion>(
    {method: 'get', url: `/am/${companyId}/report/${reportId}/${versionId}`})
  return result.data;
}

export const deleteAmReport = async (companyId: string, reportId: string) => {
  const result = await psPlatformClient<null, null>(
    {method: 'delete', url: `/am/${companyId}/report/${reportId}`})
  return result.data;
}

export const generateAmReport = async (companyId: string,
                                       reportId: string,
                                       outputMapping: OutputMapping,
                                       reportTemplateConfig: Omit<ReportTemplateConfig, 'injectionConfig' | 'name'> & {injectionConfig: ExcelReportInjectionConfig}) => {
  const result = await psPlatformClient<any, AmReportAndVersionsView>(
      {method: 'post', url: `/am/${companyId}/report/${reportId}/generate`,
      data: {companyId,
        reportId,
        outputMapping: outputMapping,
        reportTemplateConfig: reportTemplateConfig,
      } })
  return {
    ...result.data,
    config: deserializeAmReportConfig(result.data.config)
  }
}

export const uploadAmReport = async (companyId: string, reportId: string,file: File): Promise<AmReportAndVersionsView>  => {
  const formData = new FormData();
  formData.append('file', file);
  const result = await psPlatformClient<FormData, AmReportAndVersionsView>(
    {method: 'post', url: `/am/${companyId}/report/${reportId}/upload`, data: formData})
  return {
    ...result.data,
    config: deserializeAmReportConfig(result.data.config)
  } as AmReportAndVersionsView
}

export const getAmReportDownloadUrl = async (companyId: string, reportId: string, versionId: string) => {
  const result = await psPlatformClient<null, {  url: string }>(
    {method: 'post', url: `/am/${companyId}/report/${reportId}/${versionId}/get-url`})
  return result.data;
}

function deserializeAmReportConfig(config: AmReport["config"]){
  let dateFrom = new Date(config.dateRange.from);
  let dateTo = new Date(config.dateRange.to);
  return { dateRange: {from: buildUTCDate(dateFrom), to: buildUTCDate(dateTo)}};
}