import {LineItemColumn, LineItemRow, QueryResultData, ResultCell} from "../QueryResult";
import {canBeNumber, entries, normalizeString, valueAsNumber} from "../../line-item-utils/coding.utils";
import {groupBy, values} from "ramda";
import {lineItemAggregatorMap} from "../../line-item-utils/aggregators";
import {TableBuilder} from "./TableBuilder";
import {LineItem, STORE_IDENTIFYING_FIELDS_SHARED_BY_SITE_LINE_ITEMS, ValueType } from "../../line-items";
import {ResultOptions} from "./QueryResultOptions";
import {FinancialValueType, NumericFinancialValueTypes} from "../../fin-math/FinancialValueTypes";
import {formatValueWithValueType} from "../../formatting";
import {ROW_AGGREGATE_COLUMN_ID} from "./queryRunnerCommons";

export class GridTableBuilder extends TableBuilder {


  /**
   * The build Grid creates a grid grouping by rowDimension and showing the lineItems as columns as this:
   *
   * For example if rowDimension = fields.Sites and columnDimension = fields.shortName
   *
   * |  Sites |  LineItem1  |  LineItem2  |  LineItem3  |
   * |  Site1 |  Value1     |  Value2     |  Value3     |
   * |  Site2 |  Value4     |  Value5     |  Value6     |
   *
   * It only shows the first  value of the line item per cell.
   *
   * @private
   */
  public buildTable(): QueryResultData {

    let options = this.options;

    if(!options.grid) {
      throw new Error("Grid options are required to build a grid table");
    }
    
    let gridOptions = options.grid;

    let rowColumnName = gridOptions.rowDimensionLabel ||gridOptions.rowDimension;
    let rowDimension = normalizeString(options.grid!.rowDimension);
    let columnDimension = normalizeString(options.grid!.columnDimension);
    let lineItemsAggregationDef = gridOptions.lineItemAggregations;


    let lineItems = this.visibleLineItems;
    let grouped = groupBy(li =>
      this.getFieldStr(li.name, rowDimension), lineItems);

    let res: QueryResultData = {
      rows: [],
      columns: []
    }

    res.columns.push({
      type: 'header',
      columnId: "name",
      text: rowColumnName
    });

    if (options.grid?.aggregateColumnConfig) {
      res.columns.push({
        type: 'header',
        columnId: ROW_AGGREGATE_COLUMN_ID,
        text: 'Aggregate'
      })
    }

    let addedColumns = new Set<string>();
    let totalByColumnDimension: Record<string, ValueType> = {};
    let columnFormattingsMap: Record<string, {valueType: FinancialValueType, decimalPlaces: number | undefined}> = {};

    for (let li of lineItems) {
      let columnId = normalizeString(this.getFieldStr( li.name, columnDimension))
      if(!columnId) {
        continue;
      }
      if(columnId === "NTP"){
        console.info("GUYS", gridOptions?.columnOverrides?.[normalizeString(columnId)]?.label)
      }
      let columnLabel = gridOptions?.columnOverrides?.[normalizeString(columnId)]?.label ?? this.getFieldStr( li.name, columnDimension);
      // This is to avoid collision with the main identifying pinned column, with columnId name
      if(columnId === 'name'){
        columnId = 'site-name';
        li.fields.addField(columnDimension, columnId)
      }
      if(!addedColumns.has(columnId)) {
        res.columns.push({
          type: 'header',
          columnId,
          text: columnLabel,
        });
      }
      addedColumns.add(columnId);
    }

    for (let items of values(grouped)) {

      let rowDimensionValue =  this.getFieldStr( items[0].name, rowDimension);

      if(!rowDimensionValue) {
        continue;
      }

      let row: LineItemRow = {
        name: {
          text: this.getFieldStr( items[0].name, rowDimension),
          columnId: rowDimension,
          type: 'header',
          value: this.getFieldStr( items[0].name, rowDimension)
        }
      };

      for (let columnLineItem of items) {

        let columnId = normalizeString(
          this.getFieldStr( columnLineItem.name, columnDimension)
        );
        if(!columnId) {
          continue;
        }

        let timeRange = gridOptions?.columnOverrides?.[normalizeString(columnId)]?.aggregateOverEntireTimePeriod ? this.timeIndex.getRange() : (gridOptions.range || this.timeIndex.getRange());
        let value = columnLineItem.getTotal(timeRange, this.execution!,
          lineItemAggregatorMap(lineItemsAggregationDef?.lineItemMap ?? {},
            lineItemsAggregationDef?.defaultAggregator)(columnLineItem));
        let lineItemValueType = this.getValueType(columnLineItem);
        let decimalPlaces = this.getDecimalPlaces(columnLineItem);
        columnFormattingsMap[columnId] = {valueType: lineItemValueType, decimalPlaces: typeof decimalPlaces === "number" ? decimalPlaces: undefined};
        if(row[columnId]){
          value = this.aggregateCellValues(row[columnId].value, value, lineItemValueType);
        }
        row[columnId] = {
          text: this.getFormattingFunction(columnLineItem)(value),
          columnId,
          type: 'lineItemValue',
          value: value
        }
        Object.assign(row, this.metadataCellsForLiAggregate(columnLineItem, columnDimension));
      }
      if(options.grid?.aggregateRowConfig || options.grid?.aggregateColumnConfig){
        let rowAggregate: ValueType = 0;
        for (let columnId in row) {
          if(columnId && row[columnId] && row[columnId].type === "lineItemValue"){
            if(options.grid?.aggregateRowConfig){
              totalByColumnDimension[columnId] = this.aggregateCellValues(totalByColumnDimension[columnId] ?? 0, row[columnId].value, columnFormattingsMap[columnId]?.valueType ?? "number")
            }
            if(options.grid?.aggregateColumnConfig){
              rowAggregate = this.aggregateCellValues(rowAggregate, row[columnId].value, columnFormattingsMap[columnId]?.valueType ?? "number")
            }
          } else if(row[columnId].type === "lineItemValue") {
            console.warn("This column shouldn't have been skipped from the aggregation", row[columnId], columnId)
          }
        }
        if(options.grid?.aggregateColumnConfig){
          // @TODO: This will always have the formatting as of the first column.
          let firstColumnFormattings = Object.values(columnFormattingsMap)?.[0];
          row[ROW_AGGREGATE_COLUMN_ID] = {
            columnId: ROW_AGGREGATE_COLUMN_ID,
            text: formatValueWithValueType(rowAggregate, firstColumnFormattings.valueType ?? "number",  firstColumnFormattings.decimalPlaces ?? undefined),
            type: 'metadata',
            value: rowAggregate
          };
        }
      }
      res.rows.push(row);
    }

    if (options.withMetadata) {
      res.columns.push(...this.buildMetadataColumnsForLiAggregate(options, columnDimension));
    }
    res.rows.sort((a, b) => a.name.text.localeCompare(b.name.text))

    if(options.grid?.aggregateRowConfig){
      res.rows.push(this.getAggregateRow(rowDimension, totalByColumnDimension, columnFormattingsMap));
    }
    return res;
  }

  buildMetadataColumnsForLiAggregate(options: ResultOptions, columnDimension: string): LineItemColumn[] {
    const columns = [];
    const fieldNames = options.withMetadata;

    let specificFieldsToSelect = (Array.isArray(fieldNames) && fieldNames.length > 0) ? fieldNames.map(normalizeString) : null;
    let metadataColumnsByKey: Record<string, LineItemColumn> = {}
    // we should iterate over line items grouped by rowDimension...
    for (let li of this.visibleLineItems) {
      for (let key of li.fields.getAllKeys()) {
        if (specificFieldsToSelect && !specificFieldsToSelect.includes(normalizeString(key))) {
          continue;
        }
        let liColumnId = normalizeString(this.getFieldStr(li.name, columnDimension))
        let fieldKey = normalizeString(key).startsWith("store_") ? normalizeString(key) : `fields.${normalizeString(key)}`;
        let mKey = STORE_IDENTIFYING_FIELDS_SHARED_BY_SITE_LINE_ITEMS.map(normalizeString).includes(normalizeString(key)) ? fieldKey : `${liColumnId}-${fieldKey}`;
        let column = metadataColumnsByKey[mKey];
        let columnLabel = options?.grid?.columnOverrides?.[normalizeString(mKey)]?.label ?? (takeLastPartOfPath(li.fields.getFieldName(key) || "") || "");
        if (!column) {
          column = {
            columnId: mKey,
            text: columnLabel,
            type: "metadata-header"
          };
          metadataColumnsByKey[mKey] = column;
          columns.push(column);
        }
      }
    }
    return columns;
  }

  metadataCellsForLiAggregate(lineItem: LineItem, columnDimension: string) {
    let liColumnId = normalizeString(this.getFieldStr(lineItem.name, columnDimension))
    let cells: Record<string, ResultCell> = {}
    entries(lineItem.fields.getFieldMap()).forEach(([key, field]) => {
      //Add metadata. prefix for user defined metadata, otherwise don't use it
      let fieldKey = normalizeString(key).startsWith("store_") ? normalizeString(key) : `fields.${normalizeString(key)}`
      let mKey = STORE_IDENTIFYING_FIELDS_SHARED_BY_SITE_LINE_ITEMS.map(normalizeString).includes(normalizeString(key)) ? fieldKey : `${liColumnId}-${fieldKey}`;
      cells[mKey] = {
        type: 'metadata',
        columnId: mKey,
        text: field.value?.toString() || "",
        value: field.value
      }
    });

    return cells;
  }

  private aggregateCellValues(previousCellValue: ValueType, cellValueToAppend: ValueType, valueType: FinancialValueType){
    if(NumericFinancialValueTypes.map(normalizeString).includes(valueType as any)){ // No aggregation for non-numeric valueTypes
      if(canBeNumber(previousCellValue) && canBeNumber(cellValueToAppend)){ // what about strings, nothing to do here. If date nothing to do here
        return valueAsNumber(previousCellValue) + valueAsNumber(cellValueToAppend);
      }
    }
    return cellValueToAppend;
  }

  private getAggregateRow(rowDimension: string, totalByColumnDimension: Record<string, ValueType>, columnFormattingsMap: Record<string, {valueType: FinancialValueType, decimalPlaces: number | undefined}>){
    let aggregateRow: LineItemRow = {
      name: {
        text: 'Aggregate',
        columnId: rowDimension,
        type: 'header',
        value: 'Aggregate'
      }
    };
    for(let colId in totalByColumnDimension){
      let columnTotal = totalByColumnDimension[colId];
      let columnFormattings = columnFormattingsMap[colId];
      if(columnTotal !==undefined){
        aggregateRow[colId] = {
          text: formatValueWithValueType(columnTotal, columnFormattings?.valueType ?? "number", columnFormattings?.decimalPlaces ?? undefined),
          columnId: colId,
          type: 'lineItemValue',
          value: columnTotal
        }
      }
    }
    return aggregateRow;
  }
}

function takeLastPartOfPath(path: string) {
  return path.split(".").pop();
}