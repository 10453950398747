import {LineItem} from "./LineItem.model";
import {DATA_ERROR, LineItemValue, NO_DATA, ValueType} from "./LineItemValue.model";
import {ceilDate, getRange, TimeStream, timeUnitsDifference} from "../Time.model";
import {convertToNumberOrString, valueAsNumber} from "../line-item-utils/coding.utils";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {isTimedLineItem, TimedLineItem} from "./TimedLineItem";
import {sum} from "ramda";
import {average, convertRate, IRR, IRR2, norminv, NPV, PMT, PV} from "../fin-math/utils";
import {convertRate as convertRateFromNodeIRR, irr, RateInterval, xirr, XirrInput} from "node-irr";
import {differenceInMonths, getDaysInMonth} from "date-fns";
import {TimeDefinition} from "./TimeDefinition";
import {AggregatorMethod, runAggregatorMethod} from "../line-item-utils/aggregators";
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {reportLineItemError} from "../line-item-utils/ErrorReporting";
import {
    addTime,
    buildTimeRangeFromGranularity, getLastDateOfPeriodInUTC,
    isLowerGranularity,
    rangeMap,
    truncateTime,
    truncateTimeStamp
} from "../TimeGranularity";
import {buildUTCDate, TimeRange, TimeUnit, TimeUnits} from "../Time.types";
import {DslFormulaPreprocessor} from "../line-item-utils/DslFormulaPreprocessor";
import {TimedRawLineItem} from "./TimedRawLineItem";

export type CalculatedLineItemFn = (t: TimeUnit, r: PartialExecution) => ValueType;

export function buildTimedCalculatedLineItem(name: string,
                                             timeDefinition: TimeDefinition,
                                             fn: CalculatedLineItemFn | string,
                                             fields: Record<string, ValueType> | LineItemsFieldSet = {}) {

    if(fields instanceof LineItemsFieldSet) {
        return new TimedCalculatedLineItem(name, timeDefinition, fn, fields);
    }

    return new TimedCalculatedLineItem(name, timeDefinition , fn, LineItemsFieldSet.fromMap(fields));
}

export const TIMED_CALCULATED_LINE_ITEM_TYPE = "TimedCalculatedLineItem"

export class TimedCalculatedLineItem extends TimedLineItem {

    _discriminator: "timed" = "timed";

    private childLineItem?: LineItem;

    cache: Record<string, LineItemValue> = {}

    precomputedValues?: TimeStream<LineItemValue> = undefined

    constructor(name: string,
                public timeDefinition: TimeDefinition,
                public fn: CalculatedLineItemFn | string,
                fields: LineItemsFieldSet,
                childLineItem?: LineItem
    ) {
        super(name, fields);
        this.childLineItem = childLineItem;
    }

    get type(): string {
        return TIMED_CALCULATED_LINE_ITEM_TYPE
    }

    getChildLineItem() {
        return this.childLineItem;
    }

    getTimeIndexStartDate(report: PartialExecution) {
        return truncateTime(this.timeDefinition.granularity, report.getTimeIndex().startDate);
    }

    getTimeIndexEndDate(report: PartialExecution) {
        return ceilDate(this.timeDefinition.granularity, report.getTimeIndex().endDate);
    }


    preComputeValues(execution: PartialExecution) {

        let timeStream = new TimeStream<LineItemValue>();

        for (let date = this.getTimeIndexStartDate(execution); date < this.getTimeIndexEndDate(execution); date = addTime(date, 1, this.timeDefinition.granularity)) {
            let value = this.getValue(date.getTime(), execution, this.timeDefinition.granularity);
            if (value.value == 0) {
                continue;
            }
            timeStream.add(
              truncateTimeStamp(this.timeDefinition.granularity, date.getTime()),
              this.getValue(date.getTime(), execution, this.timeDefinition.granularity))
        }

       this.precomputedValues = timeStream;
    }

    serialize(): any {

        return {
            name: this.name,
            fields: this.fields.serialize(),
            type: this.type,
            fn: this.fn,
            timeStream: this.precomputedValues?.serialize(),
            timeDefinition: this.timeDefinition.serialize(),
            childLineItem: this.childLineItem?.serialize()
        }
    }

    static deserialize(data: any): TimedCalculatedLineItem {

        let timeDef = TimeDefinition.deserialize(data.timeDefinition);
        let cl = new TimedCalculatedLineItem(data.name,
          timeDef,
          data.fn,
          LineItemsFieldSet.deserialize(data.fields));

        cl.childLineItem = data.childLineItem && TimedRawLineItem.deserialize(data.childLineItem)
        return cl;
    }

    getFromCache(cacheKey: string): LineItemValue {
        return this.cache[cacheKey];
    }

    getCacheKey(report: PartialExecution, timeQuery: TimeUnit, targetGranularity: TimeUnits): string {
        return `${report.executionId}:${timeQuery}:${targetGranularity}`
    }

    setCache(cacheKey: string, value: LineItemValue) {
        this.cache[cacheKey] = value
        return value;
    }

    getValue(timeQuery: TimeUnit, report: PartialExecution, targetGranularity?: TimeUnits): LineItemValue {
       // timeQuery = truncateTimeStamp(this.timeDefinition.granularity, timeQuery);

        let cacheKey = this.getCacheKey((report as any) as PartialExecution, timeQuery, targetGranularity!);
        let cacheValue = this.getFromCache(cacheKey);
        if(cacheValue !== undefined) {
            return cacheValue;
        }

        if(!targetGranularity) {
            targetGranularity = this.timeDefinition.granularity;
        }

        if(targetGranularity === this.timeDefinition.granularity) {
            let valueObject = this.runFunction(timeQuery, report, targetGranularity);

            return this.setCache(cacheKey, valueObject);
        }

        if(isLowerGranularity(targetGranularity, this.timeDefinition.granularity)) {
            //TODO: Using "repeat" spread strategy, support other spreaders
            targetGranularity = this.timeDefinition.granularity;
        }

        let values: ValueType[] = [];

        let range = buildTimeRangeFromGranularity(timeQuery, this.timeDefinition.granularity, targetGranularity!);

        for(let t = range.start; t < range.end; t = addTime(new Date(t), 1, this.timeDefinition.granularity).getTime()) {
            let valueObject = this.runFunction(t, report, targetGranularity);
            if(!valueObject.error) {
                values.push(valueObject.value);
            }
        }

        if(values.length === 0) {
            return this.setCache(cacheKey, NO_DATA);
        }

        return this.setCache(cacheKey, new LineItemValue(runAggregatorMethod(this.timeDefinition.aggregator, values)));
    }

    extend(lineItem: LineItem) {
        this.childLineItem = lineItem;
        return this;
    }

    runFunction(t: TimeUnit, report: PartialExecution, targetGranularity: TimeUnits) {

        let startTime = this.getTimeIndexStartDate(report).getTime();
        if(t < startTime) {
            return NO_DATA;
        }

        try {
            const fn = this.buildFn(t, report, targetGranularity)
            return new LineItemValue(fn());
        } catch (e) {
            reportLineItemError(`Error calling formula for line item ${this.name}`, e)
            this.addError(t, '#CALCULATED_LINE_ITEM!',
              e instanceof Error ? e : new Error('Unknown error: ' + JSON.stringify(e)))
            return DATA_ERROR;
        }
    }


    getReportLineItemValue(liName: string, t: TimeUnit, report: PartialExecution, atGranularity?: TimeUnits | undefined): ValueType {

        if(!liName) {
            throw new Error(`Invalid line item name used from ${this.name}`);
        }

        return report.getTimedLineItemValue(liName, t, atGranularity ?? this.timeDefinition.granularity).value;
    }
    getReportLineItemValueObject(liName: string, t: TimeUnit, report: PartialExecution): LineItemValue {
        return report.getTimedLineItemValue(liName, t, this.timeDefinition.granularity);
    }

    buildBindings(_report: PartialExecution, t: TimeUnit, targetGranularity: TimeUnits): Record<string, any> {

        const report = (_report as any) as PartialExecution;

        //Difference in current line Item Units from the time Index
        const dt = timeUnitsDifference(report.getTimeIndex().startDate, new Date(t), this.timeDefinition.granularity);
        const dtwrt = (date: Date, granularity = this.timeDefinition.granularity) => {
            return timeUnitsDifference(date, new Date(t), granularity)
        }
        const currentLineItem = this;

        const getActualTime = (dtOverride?: number) => {
            if(dtOverride === undefined) {
                return t;
            }

            /*
                To get the actual time represented by dt, we need to add dt to the start date of the time index
                But it is important to truncate the time index start date to the granularity of the current line item
                Because the time index granularity is not necessarily aligned with the current line item granularity
             */
            return addTime(this.getTimeIndexStartDate(report),
              dtOverride,
              this.timeDefinition.granularity
            ).getTime();

        }

        const pastNValues = (liName: string, n: number) => {
            liName = resolveName(liName);
            reportMissingLiName(report, currentLineItem, liName, t)
            let values: ValueType[] = []
            for(let i = 0; i < n; i++) {
                let valueObject = currentLineItem.getReportLineItemValueObject(liName, getActualTime(dt-i), report);
                if(!valueObject.error) {
                    values.push(valueObject.value);
                }

            }
            return values;
        }

        const valuesInTimeRange = (liName: string, from: number, to: number) => {
            liName = resolveName(liName);
            let cacheKey = `valuesInTimeRange:${liName}:${from}:${to}`;

            if(report.getCache(cacheKey)){
                return report.getCache(cacheKey)
            }

            let range =  {
                start: from,
                end: to
            }

            let values: ValueType[] = [];

            for(let time of rangeMap(range, this.timeDefinition.granularity)) {
                values.push(currentLineItem.getReportLineItemValue(liName, time, report));
            }

            return report.setCache(cacheKey, values)
        }

        const valuesInRelTimeRange = ( liName: string, from: number, to: number) => {
            liName = resolveName(liName);
            from = getActualTime(from);

            let cacheKey = `valuesInRelTimeRange:${liName}:${from}:${to}`;

            if(report.getCache(cacheKey)){
                return report.getCache(cacheKey)
            }

            if(to < 0) {
                to = report.getTimeIndex().endDate.getTime() + getActualTime(-to);
            } else {
                to = getActualTime(to + 1);
            }

            return report.setCache(cacheKey, valuesInTimeRange(liName, from, to))
        }

        const allValues =  (liName: string, options: {
            returnValueAsNumber?:boolean,
            useLastDateOfPeriod?:boolean,
            useReferencedLineItemGranularity?:boolean
        } = {}) => {
            liName = resolveName(liName);
            let cacheKey = `allValues:${liName}:${JSON.stringify(options)}`;

            if(report.getCache(cacheKey)){
                return report.getCache(cacheKey)
            }

            let values: {time: number, value: ValueType}[] = []
            let range = {
                start: report.getTimeIndex().startDate.getTime(),
                end: report.getTimeIndex().endDate.getTime(),
            }
            let granularity = this.timeDefinition.granularity;
            let referencedLi = null;
            if(options?.useReferencedLineItemGranularity){
                referencedLi = report.getDataSet().getLineItem(liName);
                if(referencedLi && isTimedLineItem(referencedLi)){
                    granularity = referencedLi.getTimeDefinition().granularity
                }
            }
            for(let time of rangeMap(range, granularity)) {
                let timeToLookAt = time;
                if(options?.useLastDateOfPeriod){
                    timeToLookAt = getLastDateOfPeriodInUTC(buildUTCDate(new Date(time)), granularity).getTime()
                }
                let value = currentLineItem.getReportLineItemValue(liName, timeToLookAt, report, granularity);
                if(options?.returnValueAsNumber){
                    value = valueAsNumber(value);
                }
                values.push({time: timeToLookAt, value});
            }

            report.setCache(cacheKey, values)

            return values;
        }

        const timeOfFirstValue = (liName: string) => {
            liName = resolveName(liName);
            let cacheKey = `timeOfFirstValue:${liName}`;
            let fromCache = report.getCache(cacheKey);
            if(fromCache !== undefined){
                return fromCache;
            }

            let li = allValues(liName);

            for(let v of li) {
                if(v.value !== 0) {
                    return report.setCache(cacheKey, v.time)
                }
            }

            return report.setCache(cacheKey, 0)
        }

        const timeOfLastValue = (liName: string) => {
            liName = resolveName(liName);
            let cacheKey = `timeOfLastValue:${liName}`;

            let fromCache = report.getCache(cacheKey);

            if(fromCache !== undefined){
                return fromCache;
            }

            let li = allValues(liName);
            for(let i = li.length - 1; i >= 0; i--) {
                if(li[i].value !== 0) {
                    return report.setCache(cacheKey, li[i].time)
                }
            }
            return report.setCache(cacheKey, 0)
        }

        /***
         Line Item names are unique.
         The CName should be unique within the context, this enables having nested line items\
         When there is no context, we assume that the line item is in the global context
        ***/
        const resolveName = (liName: string, contextField: string = "store_context") => {
            let valueOfContext = currentLineItem.fields.getFieldStr(contextField);


            if(valueOfContext) {
                let contextualName = `${valueOfContext}-${liName}`;

                if(report.getDataSet().getLineItem(contextualName)) {
                    return contextualName;
                }
            }

            return liName;
        }

        return  {
            //Get the value of the extended line item
            "resolveName": resolveName,
            "value": this.childLineItem?.getValue(t, report, this.timeDefinition.granularity).value || 0,
            "li": (liName: string, dtOverride?: number) => {
                liName = resolveName(liName);

                const actualTime = getActualTime(dtOverride);
                reportMissingLiName(report, currentLineItem, liName, actualTime)
                return  currentLineItem.getReportLineItemValue(liName, actualTime, report);
            },
            "lit": (liName: string, time: number | Date) => {
                liName = resolveName(liName);
                if(time instanceof Date) {
                    time = time.getTime();
                }

                return currentLineItem.getReportLineItemValue(liName, time, report);
            },
            timeOfFirstValue,
            timeOfLastValue,
            "isInTimeRangeOf": (liName: string) => {
                liName = resolveName(liName);
                if(t < timeOfFirstValue(liName) || t > timeOfLastValue(liName)) {
                    return false;
                }
                return true;
            },
            "valuesInTimeRange": valuesInTimeRange,
            "valuesInRelTimeRange": valuesInRelTimeRange,
            "all": (liName: string) => {
                liName = resolveName(liName);
                let cacheKey = `all:${liName}`;

                if(report.getCache(cacheKey)){
                    return report.getCache(cacheKey)
                }

                let range = buildTimeRangeFromGranularity(report.getTimeIndex().startDate.getTime(),
                  report.getTimeIndex().getUnit(),
                  this.timeDefinition.granularity)

                let values: ValueType[] = [];

                for(let time of rangeMap(range, this.timeDefinition.granularity)) {
                    values.push(currentLineItem.getReportLineItemValue(liName, time, report));
                }

                return report.setCache(cacheKey, values)
            },
            "allAsNumbers": (liName: string, useLastDateOfPeriod:boolean = false, useReferencedLineItemGranularity: boolean = false)=>{
                liName = resolveName(liName);
                return allValues(liName, {returnValueAsNumber: true, useLastDateOfPeriod, useReferencedLineItemGranularity})
            },
            "fv": (name: string) => {
                return convertToNumberOrString(currentLineItem.fields.getField(name)?.value || 0);
            },
            "f": (name: string, value: string, dtOverride?: number) => {
                const actualTime = getActualTime(dtOverride)
                return report.getDataSet().getByField(name, value)
                  .map(li => currentLineItem.getReportLineItemValue(li.name, actualTime, report))
            },
            "pastNValues": pastNValues,
            "annualized": (liName: string) => {
                liName = resolveName(liName);
                if(currentLineItem.timeDefinition.granularity !== "months") {
                    reportLineItemError("Cannot annualize a non-monthly line item", currentLineItem);
                   return 0;
                }

                let pastValues = pastNValues(liName, 12).map(v => v as number);

                if(pastValues.length < 12) {
                    return average(pastValues) * 12;
                }

                return pastValues.reduce((a, b) => a + b, 0);
            },
            "cname": (name: string, dtOverride?: number) => {
                const actualTime = getActualTime(dtOverride)
                let lis = report.getDataSet().getByCanonicalName(name)
                return lis.map(li => currentLineItem.getReportLineItemValue(li.name, actualTime, report))
            },
            "cn": (name: string, dtOverride?: number) => {
                const actualTime = getActualTime(dtOverride)
                let lis = report.getDataSet().getByCanonicalName(name)
                return lis.map(li => currentLineItem.getReportLineItemValue(li.name, actualTime, report))
            },
            "daysInMonth": ()=> getDaysInMonth(t),
            "date":  new Date(t),
            "t": t,
            ...STATIC_BINDINGS,
            "dt": dt,
            "dtwrt": dtwrt
        }
    }

    _fnCache: any = undefined

    buildFn(t: TimeUnit, report: PartialExecution, targetGranularity: TimeUnits): () => ValueType {
        if(typeof this.fn === "function")
            return this.fn.bind(null, t, report)
        else {
            const bindings = this.buildBindings(report, t, targetGranularity);

            if(this._fnCache === undefined) {
                this._fnCache = new Function(...Object.keys(bindings), this.formula())
            }

            return this._fnCache.bind(null, ...Object.values(bindings))
        }
    }

    private formulaCache = undefined as string | undefined
    protected formula() {
        if (this.formulaCache === undefined) {
            const parser = new DslFormulaPreprocessor(this.fn as string, TIMED_CALCULATED_LINE_ITEMS_FN_NAMES)
            const formula = parser.parse()
            for (const error of parser.errors) {
                console.warn(`Error parsing formula for line item ${this.name}`, error)
                this.addError(0, '#CALCULATED_LINE_ITEM!', error)
            }
            this.formulaCache = formula
        }
        return this.formulaCache
    }

    clone() {
        return new TimedCalculatedLineItem(this.name, this.timeDefinition, this.fn, this.fields.clone(), this.childLineItem);
    }

    withCode(code: string): TimedCalculatedLineItem {
        return new TimedCalculatedLineItem(this.name, this.timeDefinition, code, this.fields, this.childLineItem);
    }

    withDefinition(timeDefinition: TimeDefinition) {
        return new TimedCalculatedLineItem(this.name, timeDefinition, this.fn, this.fields, this.childLineItem);
    }

    getTimeDefinition(): TimeDefinition {
        return this.timeDefinition;
    }

    getTotal(range: TimeRange, report: PartialExecution, aggregator?: AggregatorMethod): ValueType {

        let cacheKey = `${this.name}:${range.start}:${range.end}:${aggregator}`;
        let cacheValue = report.getCache(cacheKey);
        if(cacheValue !== undefined){
            return cacheValue
        }

        let values: ValueType[] = [];
        for(let t of getRange(range, this.timeDefinition.granularity)) {
            let valueObject = this.getValue(t, report, this.timeDefinition.granularity);
            if(!valueObject.error) {
                values.push(valueObject.value);
            }
        }

        return report.setCache(cacheKey, runAggregatorMethod(aggregator || this.timeDefinition.aggregator, values));
    }
}


const STATIC_BINDINGS = {
    "norminv": norminv,
    "sum": (values: ValueType[]) => {
        return sum(values.map(valueAsNumber));
    },
    "unique": (values: ValueType[]) => {
        return [...new Set(values)];
    },
    "variance": (a:number, b: number) => {
        if(b === 0)
            return 0;
        return (a - b)/b
    },
    "last": (values: ValueType[]) => {
        return values[values.length - 1];
    },
    "first": (values: ValueType[]) => {
        return values[0];
    },
    "abs": Math.abs,
    "min": (list: number[]) => {
        return list.reduce((a, b) => Math.min(a, b), list[0])
    },
    "max": (list: number[]) => {
        return list.reduce((a, b) => Math.max(a, b), list[0])
    },
    "avg": (list: number[]) => {
        return sum(list) / list.length
    },
    "NPV": NPV,
    "PV": PV,
    "IRR": IRR,
    "IRR2": IRR2,
    "irr": irr,
    "xirr": (inputs: { value: number; time: number }[])=> {
        // @TODO: See if we need to send the granularity too..
        let transactions = inputs.filter((i)=>i.value !== 0 && !Number.isNaN(i.value)).map((inp)=>({date: buildUTCDate(new Date(inp.time)), amount: inp.value}));
        if(transactions.length ===0) return 0;
        let annualRate = convertRateFromNodeIRR(xirr(transactions).rate, RateInterval.Year);
        return annualRate;
    },
    "PMT": PMT,
    "IF": (check: ValueType, value: ValueType, elseValue: ValueType) =>
        check !== 0 ? value : elseValue,
    "ifnz": (check: ValueType, value: ValueType) => check !== 0 ? value : 0,
    "convertRate": convertRate,
    "rounddown": Math.floor,
    "month_diff": (a: Date, b: Date) => differenceInMonths(a, b),
    "toDate": (value: string | number) => new Date(value),
    "sumOver": (values: ValueType[]) => {
        return sum(values.map(valueAsNumber));
    }
}

function reportMissingLiName(report: PartialExecution, li: LineItem, liName: string, t: number) {
    if(!report.getDataSet().getLineItem(liName)) {
        //console.warn('During ' + li.name + ' not found line item ' + liName)
        li.addError(t, '#CALCULATED_LINE_ITEM!', new Error(`Line item [${liName}] doesn't exist`));
    }
}

export const TIMED_CALCULATED_LINE_ITEMS_FN_NAMES = new Set(['li', 'liv', 'sku', 'skuv', 'skus', 'sum', 'annual_sum'])

