import {WhatIfScenarioConfig, WhatIfScenarioInputValues, WhatIsScenarioRecord} from "../../ps-types";
import {Container, Grid} from "semantic-ui-react";
import {LocatorsMapInput} from "./LocatorsMapInput";
import React from "react";

export function GlobalInputs({config, inputData, updateInputData}: {
  config: WhatIfScenarioConfig,
  inputData: WhatIsScenarioRecord['inputData'],
  updateInputData: (scenario: WhatIsScenarioRecord['inputData']) => void,
}) {

  const globalInputs = inputData.globalInputs!;

  const handleOnValuesUpdated = (values: WhatIfScenarioInputValues) => {
    updateInputData({...inputData, globalInputs: values});
  }

  return <Container>
    <h2>Global Inputs</h2>
    <Grid>{config && <LocatorsMapInput
        inputValues={globalInputs}
        mappingConfig={config.mappingConfig}
        onValuesUpdated={handleOnValuesUpdated}
    />}
    </Grid>
  </Container>
}